
.page-about-us {
    h1 {
        font-size: 16px;
    }

    @include brk-small-mobile {
        //overflow-x: hidden;
    }

    .site-content {

        &.landing {
            margin: 0;
        }

        .intro {
            margin-bottom: 100px;

            .intro-text {
                .headlines {
                    .heading {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .clients {
            background-color: $super-light-grey;
            padding: 60px 100px;
            text-align: center;

            @include brk-mobile {
                padding: 60px 5%;
            }
            @include brk-small-mobile {
                padding: 60px 0;
            }

            h3 {
                margin-bottom: 40px;
            }

            &-grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: $max-width;
                margin: 0 auto 40px;

                .client {
                    position: relative;
                    flex-basis: 20%;
                    margin: 35px auto;
                    height: 50px;
                    min-width: 170px;

                    img {
                        user-select: none;
                        pointer-events: none;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        display: block;
                        filter: grayscale(1);
                        transition: filter .3s;
                        max-width: 150px;
                        max-height: 60px;
                    }

                    &:hover, &:active {
                        img {
                            filter: grayscale(0);
                        }
                    }
                }
            }

        }

        &.landing {
            .clients {
                background-color: $white;

                &-grid {
                    margin-bottom: 0;
                    grid-gap: 20px;
                    .client {
                        margin: 35px 0;
                        flex-basis: unset;
                    }
                }
            }
        }

        .team {
            margin: 0 auto;
            padding: 50px 0;

            h3, p {
                text-align: center;
                max-width: 350px;
                margin: 25px auto;
            }

            .listed-members {
                margin: 80px auto;
                display: flex;
                flex-wrap: wrap;
                width: $max-width;
                max-width: $max-width;
                justify-content: center;

                .member {
                    flex-basis: 25%;
                    position: relative;
                    height: 300px;
                    max-width: 250px;
                    overflow: hidden;

                    .press-link {
                        position: absolute;
                        width: 100%;
                        height: 260px;
                        bottom: 0;
                        z-index: 99999;
                    }

                    &-background {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 5;

                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .7) 100%);
                            z-index: 55;
                        }
                    }

                    &-background-image {
                        position: absolute;
                        height: 100%;
                        max-height: 300px;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);
                        z-index: 5;
                    }

                    &-details {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        z-index: 55;

                        .member-contact {
                            width: 100%;
                            position: relative;
                            display: flex;

                            .member-phone {
                                width: 85%;
                                padding: 20px 10px 5px;
                                text-align: right;
                                color: $white;
                                font-size: 14px;
                                transition: all .5s ease;
                                @include font-source-sans;
                                text-decoration: none;
                                background: transparent;

                                &:hover {
                                    background: $white;
                                    color: $medium-grey;
                                }
                            }

                            .phone-dummy, .mail-dummy {
                                width: 100%;
                                min-height: 40px;
                            }

                            .member-email {
                                width: 15%;
                                padding: 19px 8px 0;
                                transition: background-color .5s ease;
                                border-left: 1px solid $white;

                                &:before {
                                    background-color: $white;
                                }

                                .grey {
                                    display: none;
                                }

                                img {
                                    position: absolute;
                                    width: 20px;
                                }

                                &:hover {
                                    background-color: $white;

                                    .grey {
                                        display: block;
                                    }
                                }
                            }
                        }

                        .member-name {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            padding-right: 10px;
                            padding-bottom: 10px;
                            width: 100%;

                            h4, p {
                                text-align: right;
                                @include font-source-sans;
                                margin: 5px 0;
                                color: $white;
                            }

                            p {
                                width: 95%;
                                margin-left: auto;
                            }
                        }
                    }

                    @include brk-max-width {
                        flex-basis: 50%;
                    }

                    @include brk-tablet {
                        flex-basis: 100%;
                    }

                    @media screen and (max-width: 500px) {
                        max-width: 50%;
                        .member-contact {
                            .member-email {
                                width: 30%;
                                padding: 19px 10px 0;
                            }
                        }
                    }
                }

                @include brk-max-width {
                    width: 100%;
                }
            }
        }

        .associations {
            padding: 50px 0 50px;
            background-color: $super-light-grey;

            h3 {
                text-align: center;
                margin-bottom: 40px;
            }

            .grid {
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                justify-content: space-between;
                max-width: $max-width;


                @include brk-max-width {
                    justify-content: center;
                }

                @include brk-small-mobile {
                    justify-content: space-around;
                }


                .associate {
                    margin: 25px 15px;
                    width: 150px;
                    height: 80px;
                    display: flex;

                    @include brk-max-width {
                        width: 30%;
                    }


                    @include brk-small-mobile {
                        width: 50%;
                    }

                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                    }
                }
            }
        }
    }
}
