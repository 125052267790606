.page-know-how {
    @include brk-mobile {
        .title-image {
            display: none;
        }
    }

    .site-content {
        padding-bottom: 30px;

        .page-title {
            width: 90%;
            max-width: $max-width;
            margin: 0 auto 80px;
        }

        .tab-content {
            .entry-grid {
                display: flex;
                width: 100%;
                max-width: $max-width;
                margin: auto;
                justify-content: center;
                flex-wrap: wrap;
                padding-bottom: 80px;

                &-workshop-type {
                    z-index: 3;
                    padding-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                    span {
                        color: $white;
                        font-size: 12px;
                        @include font-source-sans;
                    }
                    border-bottom: 1px solid $white;
                }

                &-card {
                    position: relative;
                    width: 240px;
                    height: 290px;
                    background: $box-blue;
                    cursor: pointer;
                    @include brk-mobile {
                        width: 320px;
                        height: 380px;
                    }

                    &-info {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        padding: 15px 10px;
                    }

                    &-type {
                        position: relative;
                        max-width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        transform: translate3d(0, 0, 0);
                        transition: all 0.3s ease;
                        z-index: 3;

                        span {
                            color: $white;
                            font-size: 12px;
                            @include font-source-sans;
                        }

                        img {
                            height: 15px;
                            margin-left: 5px;
                        }
                    }

                    &-title {
                        position: relative;
                        text-align: right;
                        font-size: 18px;
                        line-height: 22px;
                        @include font-source-sans-semibold;
                        color: $white;
                        z-index: 3;
                    }

                    &-background {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        overflow: hidden;
                        z-index: 1;

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: black;
                            transition: opacity 0.3s linear;
                            opacity: 0.2;
                            z-index: 2;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            object-fit: cover;
                            z-index: 1;
                        }
                    }

                    &:hover {
                        .entry-grid-card-background {
                            animation: clippath 0.5s linear;
                            clip-path: polygon(
                                0 100%,
                                0 100%,
                                0 100%,
                                0 100%,
                                0 100%,
                                0 100%
                            );
                        }

                        .entry-grid-card-type {
                            transform: translate3d(100%, 0, 0);
                        }
                    }
                }
            }
        }
    }

    .sidebar-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: none;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.5s linear;
        z-index: 10;

        .content-overlay {
            position: relative;
            height: 100%;
            width: 40%;
            cursor: pointer;
            opacity: 0;

            @include brk-mobile {
                display: none;
            }

            img {
                position: absolute;
                top: 120px;
                right: 20px;
                width: 50px;
                z-index: 2;
            }
        }

        .arrow-close {
            position: absolute;
            top: 35px;
            right: 110px;
            width: 50px;
            z-index: 9;

            @include brk-small-mobile {
                top: 25px;
                right: 90px;
            }
        }

        .sidebar {
            position: relative;
            height: 100%;
            overflow-y: scroll;
            width: 60%;
            background-color: $white;
            opacity: 0;
            animation: slide-in-right 0.7s ease forwards;

            .loading-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .loader {
                border: 8px solid $super-light-grey;
                border-top: 8px solid $medium-grey; /* Blue */
                border-radius: 50%;
                width: 80px;
                height: 80px;
                animation: spin 2s linear infinite;
            }

            @include brk-tablet {
                width: 70%;
            }
            @include brk-mobile {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &.active {
            display: flex;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
}
