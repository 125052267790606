body.landingpage {

    .header {
        box-shadow: none !important;
    }

    .header.active {
        background: transparent !important;
    }

    .header-right {
        display:none;
    }

    .title-image {
        height: 430px;

        @include brk-mobile {
            height: 320px;
        }

        @include brk-small-mobile {
            height: 250px;
        }

        img {
            height: 100%;
        }


        .title {
            width: 100%;
            margin: auto;
            display: block;
            height: 100%;
            padding: 160px 0 80px;

            @include brk-mobile {
                padding: 80px 0;
            }

            @include brk-small-mobile {
                padding: 70px 0;
            }

            h1 {
                text-align: center;
                line-height: 1.4em;
                margin-bottom: 30px;

                @include brk-mobile {
                    font-size: 2.4em;
                }

                @include brk-small-mobile {
                    font-size: 1.6rem;
                }
            }

            a {
                border-color: #ffffff;
                color: #ffffff;
                margin: auto;
                display: table;
                font-size: 14px;
            }
        }
    }


    .alert {
        position: fixed;
        top: 0;
        left:0;right:0;
        margin: auto;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        opacity: 0;
        transition: opacity .7s ease;
        box-shadow: 0 0 10px 2px $headline-grey;
        pointer-events: none;

        &-content {
            background-color: #fff;
            position: absolute;
            top:0;bottom:0;
            left:0;right:0;
            margin: auto;
            width: 80%;
            padding: 60px 40px;
            max-width: 550px;
            height: fit-content;
        }

        .close-alert {
            position: absolute;
            top: 20px;
            right: 25px;
            width: 20px !important;
            height: 20px !important;
            margin: 0;
            min-width: unset;
            max-width: unset;
            &:hover {
                cursor: pointer;
            }
        }
        &.active {
            opacity: 1;
            pointer-events: all;
            z-index: 999;
        }

        .message {
            margin-bottom: 10px;
            @include font-source-sans;
        }
    }

    .site-content {

        .section.inview {
            h3 {
                animation: fadeIn .7s forwards;
            }
        }

        .section.inview {
            h3 {
                animation: fadeIn .7s forwards;
            }
        }

        h3 {
            @include font-playfair;
            font-size: 1.6em;
        }

        .intro {
            width: 60%;
            margin-bottom: 60px;

            @include brk-mobile {
                width:85%;
            }

            .intro-text {
                @include brk-mobile {
                    margin-right: 0;
                }
            }

            .headlines {

                .headline {
                    display: flex;

                    &-icon {
                        margin-right: 45px;
                        width: 45px;
                        height: 45px;

                        img {
                            position: relative;
                            width: 40px;
                            margin: auto;
                            display: block;
                        }
                    }

                    .heading {
                        margin-bottom: 20px !important;
                        @include font-source-sans;
                        font-size: 14px;
                        line-height: 1.4em;
                        letter-spacing: 0.2px;
                        color: $medium-grey;

                        h2 {
                            font-size: 22px !important;
                            margin: 0 0 10px;
                        }
                    }
                }
            }

            .info-box {
                margin-left: auto;

                @include brk-mobile {
                    margin-bottom: 40px;
                }

                h4 {
                    margin-bottom: 20px;
                }
            }
        }

        .clients {
            background-color: $super-light-grey;
            padding: 50px 100px;
            text-align: center;

            @include brk-mobile {
                padding: 50px 5%;
            }
            @include brk-small-mobile {
                padding: 50px 0;
            }

            h3 {
                margin-bottom: 40px;
            }
            &-grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .client {
                    position: relative;
                    flex-basis: 20%;
                    margin: 35px auto;
                    height: 50px;
                    min-width: 170px;
                    img {
                        position: absolute;
                        top:0;
                        bottom:0;
                        left:0;
                        right:0;
                        margin: auto;
                        display: block;
                        filter: grayscale(1) brightness(1.8);
                        transition: filter .3s;
                        max-width: 150px;
                        max-height: 60px;
                    }
                    &:hover, &:active {
                        img {
                            filter: grayscale(0);
                        }
                    }
                }
            }
        }

        .services {
            padding: 60px 100px;
            text-align: center;

            @include brk-mobile {
                padding: 50px 5%;
            }
            @include brk-small-mobile {
                padding: 50px 0;
            }

            &-grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: space-between;


                .service {
                    overflow: hidden;
                    width: 245px;
                    height: 335px;
                    margin: 15px;

                    &-image {
                        height: 160px;

                        img {
                            height: 100%;
                        }
                    }

                    &-text {
                        height: 175px;
                        padding: 35px 20px;
                        background: $button-blue;
                        position: relative;

                        h4, p {
                            color: #ffffff !important;
                            font-size: 18px;
                        }

                        h4 {
                            @include font-source-sans-semibold;
                        }

                        p {
                            position: absolute;
                            top: 50%;
                            width: 80%;
                            left:0;right:0;
                            margin: auto;
                        }
                    }
                }
            }

            .btn-blue {
                margin-top: 40px;
                display: inline-block;
            }
        }

        .slider {
            padding: 60px 0;

            @include brk-mobile {
                padding: 50px 0;
            }
            @include brk-small-mobile {
                padding: 50px 0;
            }

            h3 {
                text-align: center;
                margin-bottom: 40px;
            }

            .picture-slider {
                margin: auto;
                display: block;
                overflow: hidden;
                max-width: $max-width;

                h4 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 16px!important;
                    margin: 10px 0;
                }
            }

            @include brk-tablet {
                .picture-slide {
                    display: flex;
                }

                .slide-box {
                    position: relative;
                    height: unset !important;
                }
                .picture-container {
                    width: 55% !important;
                }

                .slide-box {
                    width: 45% !important;
                }

                .slide-box-description {
                    padding: 40px 15% !important;
                }
            }

            @include brk-mobile {
                .picture-slide {
                    display: inline-grid;
                }

                .slide-box {
                    height: 240px !important;
                    width: 100% !important;
                }

                .picture-container {
                    width: 100% !important;
                }

                .slide-box-description {
                    h4 {
                        font-size: 20px;
                        margin-bottom: 20px;
                    }
                }
            }


            @include brk-small-mobile {
                .slide-box {
                    height: 320px !important;
                }

                .slide-box-description {
                    padding: 60px 10% !important;

                    h4 {
                        font-size: 18px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .steps {
            padding: 60px 100px;
            text-align: center;

            @include brk-mobile {
                padding: 50px 5%;
            }

            @include brk-small-mobile {
                padding: 50px 0;
            }

            .steps-grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: space-between;
                .step {
                    position: relative;
                    width: 220px;
                    height: 155px;
                    margin: 15px;
                    border: 2px solid $headline-grey;
                    padding: 10px 20px;

                    &-image, &-description {
                        height: 50%;

                        img {
                            display: block;
                            margin: auto auto auto 0;
                        }
                    }

                    &-description {
                        padding-top: 10px;
                        text-align: left;
                        .step-count {
                            color: $headline-grey;
                            font-size: 12px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .consultation-form {
            padding: 60px 35%;
            background-color: $super-light-grey;

            p {
                @include font-source-sans-semibold;
                margin: 20px 0;
            }

            @include brk-tablet {
                padding: 50px 30%;
            }

            @include brk-mobile {
                padding: 50px 20%;
            }

            @include brk-small-mobile {
                padding: 50px 10%;
            }

            #contact-form {
                display: flex;
                flex-direction: column;

                #country {
                    opacity: 0;
                    height: 0;
                    padding: 0;
                    margin: 0;
                    border: 0;
                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }

                input[type=text], input[type=email], input[type=tel], select {
                    margin: 20px 0;
                    background-color: $super-light-grey;
                    border: 0;
                    border-bottom: 1px solid $headline-grey;
                    padding-bottom: 7px;
                    font-style: italic;

                    &:focus {
                        outline: none;
                        border-bottom: 2px solid $headline-grey;
                    }
                }

                input[type=submit] {
                    background-color: transparent;
                    width: max-content!important;
                }

                textarea {
                    display:none;
                }
                p {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}


