
body.news {
    @include brk-mobile {
        header {
            position: absolute;
            height: 0px;
        }
    }
}

#news {
    background: $white;
    @include brk-mobile {
        display: none;
    }
}

.page-news {
    padding-bottom: 30px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.8);
    @include brk-mobile {
        box-shadow: none;
    }
    #content {
        padding: 130px 0 80px;
    }

    .contact-wrapper {
        display: none;

        @include brk-mobile {
            display: initial;
        }
    }
}

.page-switch {
    padding: 40px 50px 0;
    width: 250px;
    @include brk-mobile {
        display: flex !important;
    }

    .switch {
        @include font-source-sans;
        margin: 0 auto;
        color: $light-grey;
        text-decoration: none;
        overflow: hidden;

        &-text {
            pointer-events: none;
        }

        &-border {
            pointer-events: none;
            margin-top: 5px;
            width: auto;
            transform: translateX(-105%);
            border-bottom: 1px solid $medium-grey;
            transition: all 450ms ease;
        }

        &.active {
            color: $medium-grey;
            .switch-border {
                transform: translateX(0);
            }
            .switch {
                color: $light-grey;
                &.active {
                    color: $medium-grey;
                    .switch-border {
                        transform: translateX(0);
                    }
                }
            }
            .dash, .switch-border {
                border-color: $light-grey;
            }
        }
    }

    .dash {
        border-right: 1px solid $light-grey;
    }
}

.content {
    padding: 150px 0 50px;
    max-width: 740px;
    margin: 0 auto;

    h1, h2 {
        font-size: 2.8em;
        color: $medium-grey;
        width: 100%;
        text-align: center;
    }
    .news {
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        &-entry {
            margin-top: 0;
            flex-basis: 50%;
            width: 50%;
            min-width: 300px;
            padding: 0 20px;
            color: $medium-grey;


            &.move-in-view {
                opacity: 0 !important;
                transform-origin: 0 50%;
                transform: scale(1.5) translateX(-100%) !important;
                transition: all 1s 0.3s ease !important;

                &.inview {
                    opacity: 1 !important;
                    transform: scale(1) translateX(0) !important;
                    transition: none;
                }
            }


            a {
                text-decoration: none;
            }

            h3, p {
                color: $medium-grey;
            }

            .news-image {
                overflow: hidden;
                max-height: 300px;
                width: 100%;
                img {
                    max-height: inherit;
                    transition: transform 3s ease;
                    transform-origin: 50% 50%;
                }
            }

            .news-textbox {
                display: flex;
                flex-direction: column;

                a {
                    margin-right: auto;
                }
            }

            h3 {
                margin: 15px 0;
                line-height: 1.2em;
            }

            p {
                font-size: 0.8em;
                line-height: 1.2em;
                margin-bottom: 20px;
            }

            &:hover {
                .news-image {
                    img {
                        transform: scale3d(1.1, 1.1, 1.1);
                    }
                }
            }
        }

        &-entry:nth-child(2n) {
            &.move-in-view {
                transform-origin: 100% 50%;
                opacity: 0 !important;
                transform: scale(1.5) translateX(100%) !important;
                transition: all 1s 0.3s ease;
                &.inview {
                    opacity: 1 !important;
                    transform: scale(1) translateX(0) !important;
                }
            }

            margin-top: 100px;
            @include brk-mobile {
                margin-top: 0;
            }
        }

    }

    @include brk-mobile {
        padding: 50px 0;

        .news {
            margin-top: 60px;
            flex-direction: column;
            &-entry {
                flex-direction: column;
                display: flex;
                flex-basis: 100%;
                width: 95%;
                padding: 0;
                margin: 40px auto 40px;
                margin-left: 0;

                .news-image {
                    min-width: 300px;
                    width: auto;
                }

                .news-textbox {
                    padding: 0 0 0 30px;
                }

            }
            &-entry:nth-child(even) {
                flex-direction: column;
                margin: 40px auto 40px;
                margin-right: 0;

                .news-textbox {
                    padding: 0 20px 0 0;
                }
            }
        }
    }

    @include brk-mobile {
        .news {
            &-entry {
                flex-direction: column;
                width: 100%;
                max-width: 320px;
                margin: 20px auto;

                .news-textbox {
                    padding: 0 !important;
                    width: 95%;
                    margin: 0 auto;
                }
            }

            &-entry:nth-child(even) {
                flex-direction: column;
                max-width: 320px;
                margin: 20px auto;
                .news-textbox {
                    padding: 0 !important;
                    width: 95%;
                    margin: 0 auto;
                }
            }
        }
    }

    @include brk-small-mobile {
        padding: 30px 0 50px;
    }
}

.page-news-post {
    min-height: calc(100vh - 200px);

    @include brk-mobile {
        padding-bottom: 30px;
    }

    .redactor-content {
        strong {
            a {
                color: $medium-grey;
                @include font-source-sans-semibold;
                font-size: 12px;
                letter-spacing: 1px;
                text-decoration: none;
                border: 1px solid $medium-grey;
                padding: 7px 15px 8px;
                &:hover {
                    color: $white;
                    background: $medium-grey;
                    border: 1px solid $medium-grey;
                }

                &:active, &:focus {
                    outline: none;
                }
            }
        }
    }

    .arrow-back {
        position: absolute;
        left: 5%;
        top: 150px;
        width: 60px;
        transition: transform .5s ease;
        @include brk-tablet {
            top: 95px;
        }
        @include brk-mobile {
            top: 25px;
            width: 50px;
        }
        &:hover {
            transform: scale(1.1);
        }
    }

    .news-header-image {
        position: relative;
        display: flex;
        height: auto;
        overflow: hidden;
        pointer-events: none;
        margin: 150px auto 0;
        width: 100%;

        img {
            max-width: $brk-mobile;
            margin: auto;
            width: 100%;
        }

        @include brk-mobile {
            // display: none;
            margin: 80px auto 0;
            height: auto;
        }
    }

    .site-content {
        max-width: $brk-mobile;
        padding-bottom: 60px;
        min-height: unset;

        h3 {
            margin: 0 auto 20px;
        }

        @include brk-mobile {
            padding: 0 5%;
            margin-top: 30px;
        }

        .redactor-content {
            width: 100%;
            margin-bottom: 40px;
        }
    }
}

