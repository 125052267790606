
.contact-banner {
    background-color: $button-blue;
    a {
        text-decoration: none;
    }

    &.home {
        position: absolute;
        right:0;
        top: 25%;
        padding: 25px 20px;
        max-width: 280px;

        @include brk-mobile {
            max-width: unset;
            bottom: 8%;
            top: unset;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 9999;
        }

        @include brk-mobile {
            margin: auto;
            width: 90%;
            padding: 20px 15px;
        }
    }

    &.page {
        position: relative;
        margin: 30px auto 0;
        max-width: $max-width;

        a {
            display: flex;
        }

        .contact-banner-text {
            width: 100%;
            padding: 25px 20px;
            text-align: left;
        }

        .arrow-container {
            position: absolute;
            bottom: 15px;
            right: 20px;
        }

        @include brk-mobile {
            position: relative;
            width: 100%;
            padding: 20px 15px;
        }
    }

    &-image {
        position: relative;
        flex-basis: 40%;
        overflow: hidden;
        width: 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-claim {
        @include font-source-sans;
        color: $light-blue;
        font-size: 12px;
        margin-bottom: 10px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: left;
    }

    &-title {
        @include font-playfair-bold;
        color: $white;
        font-size: 20px;
        line-height: 24px;
        line-break: normal;
        letter-spacing: 0.8px;
        text-decoration: none;
        text-align: left;

        @include brk-mobile {
            font-size: 18px;
        }
    }

    .line {
        position: relative;
        margin: 10px 0;
        width: 100%;
        border-top: 1px solid $white;

        @include brk-mobile {
            display:none;
        }
    }

    .arrow-container {
        height: 16px;
        display: flex;
        justify-content: flex-end;

        @include brk-mobile {
            position: absolute;
            bottom: 15px;
            right: 20px;
        }
    }
}
