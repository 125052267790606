body.jobs {

    footer {
        display: none;
    }

}


.jobs-wrapper {
    background: $white;
    position: absolute;
    height: 100%;
    min-height: 100vh;
    top: 0;
    right: 0;
    width: 60%;
    transition: transform 1000ms ease;
    transform: translateX(100%);
    overflow-x: hidden;

    &.active {
        transform: translateX(0);
    }

    .close-btn {
        position: fixed;
        top:0;
        right:0;
        margin: 200px 100px 25px;
        width: 25px;
        transition: opacity 700ms ease;
        opacity: 0;
        cursor: pointer;
        z-index: 555555;

        &.active {
            transition-delay: .4s;
            opacity: 1;
        }

        @include brk-mobile {
            margin:0;
            right: 40px;
            top: 100px;
        }
    }

    .top-bar {
        position: sticky;
        top:0;
        right:0;
        width: 100%;
        height: 90px;
        background-color: #ffffff;
        z-index: 56;

        @include brk-mobile {
            display: none!important;
        }
    }

    .jobs {
        position: relative;
        padding: 60px 25% 40px 100px;
        z-index: 54;

        @include brk-mobile {
            margin-top: 40px;
            padding: 60px 10%;
        }

        @include brk-small-mobile {
            margin-top: 20px;
            padding: 40px 5%;
        }

        .redactor-content {
            width: 100% !important;
        }

        h1 {
            @include font-source-sans;
            font-size: 18px;
            color: $medium-grey;
        }

        .jobs-description {
            color: $medium-grey;
            margin: 20px 0;
            word-break: break-word;
            line-height: normal;
        }

        .job-list {
            margin-top: 40px;
        }

        .job {
            margin-top: 20px;
            a {
                margin-right: auto;
            }
        }
        .job-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            width: 100% !important;
            padding: 80px 100px;
            background-color: $white;
            margin: 0;
            margin-top: 80px;
            z-index: 55;

            p {
                color: $medium-grey;
                max-width: 90%;
            }

            @include brk-mobile {
                margin-top: 40px;
                padding: 60px 10%;
            }
            @include brk-small-mobile {
                margin-top: 20px;
                padding: 60px 5%;
            }
        }
    }

    #job-arrow-back {
        position: absolute;
        margin-top: 20px;
        left: 100px;
        z-index: 55;

        @include brk-mobile {
            top: 0;
            margin-top:0;
            left: 20px;
        }
    }

    .arrow {
        width: 50px;
        transform: rotate(180deg);
        z-index: 555555;

        @include brk-mobile {
            position: absolute;
            margin-bottom: 0;
        }

        @include brk-small-mobile {
            top: 20px;
        }
    }

    .no-jobs {
        margin-top: 100px;
        padding: 100px 25% 40px 100px;

        @include brk-mobile {
            margin-top: 40px;
            padding: 40px 10%;
        }

        @include brk-small-mobile {
            margin-top: 20px;
        }

        span {
            display: block;
            @include font-source-sans;
            font-size: 18px;
            color: $medium-grey;
            margin-bottom: 40px;
        }
    }

    @include brk-tablet {
        width: 70%;
    }

    @include brk-mobile{
        width: 100%;
        height: calc(100% - 100px);
        padding: 0;
        margin-bottom: 0;
        left:0;
        transition: none;

        .close-btn {
            margin: 0;
            right: 15%;
            top: -50px;
        }
    }

    @include brk-small-mobile {
        padding-bottom: 40px;
    }
}
