
@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("../fonts/SourceSansPro-Bold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/SourceSansPro-Bold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/SourceSansPro-Bold.svg") format("svg"), /* Legacy iOS */
    url("../fonts/SourceSansPro-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/SourceSansPro-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/SourceSansPro-Bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url("../fonts/SourceSansPro-SemiBold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/SourceSansPro-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/SourceSansPro-SemiBold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/SourceSansPro-SemiBold.svg") format("svg"), /* Legacy iOS */
    url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/SourceSansPro-SemiBold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/SourceSansPro-SemiBold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("../fonts/SourceSansPro-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/SourceSansPro-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/SourceSansPro-Regular.svg") format("svg"), /* Legacy iOS */
    url("../fonts/SourceSansPro-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/SourceSansPro-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/SourceSansPro-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay-Regular";
    src: url("../fonts/PlayfairDisplay-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/PlayfairDisplay-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/PlayfairDisplay-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/PlayfairDisplay-Regular.svg") format("svg"), /* Legacy iOS */
    url("../fonts/PlayfairDisplay-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/PlayfairDisplay-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/PlayfairDisplay-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay-Bold";
    src: url("../fonts/PlayfairDisplay-Bold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/PlayfairDisplay-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/PlayfairDisplay-Bold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/PlayfairDisplay-Bold.svg") format("svg"), /* Legacy iOS */
    url("../fonts/PlayfairDisplay-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/PlayfairDisplay-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/PlayfairDisplay-Bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PlayfairDisplay-Black";
    src: url("../fonts/PlayfairDisplay-Black.eot"); /* IE9 Compat Modes */
    src: url("../fonts/PlayfairDisplay-Black.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/PlayfairDisplay-Black.otf") format("opentype"), /* Open Type Font */
    url("../fonts/PlayfairDisplay-Black.svg") format("svg"), /* Legacy iOS */
    url("../fonts/PlayfairDisplay-Black.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/PlayfairDisplay-Black.woff") format("woff"), /* Modern Browsers */
    url("../fonts/PlayfairDisplay-Black.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
