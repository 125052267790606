$white: #FFFFFF;
$black: #000000;
$red: #f52222;
$button-blue: #0076AF;
$box-blue: #006DA1;
$light-blue: #24B6FF;
$sky-blue: #2FACCE;
$dark-grey: #202020;
$medium-grey: #4A4A4A;
$light-grey: #7D7D7D;
$lighter-grey: #858585;
$super-light-grey: #F4F4F4;

$headline-grey: #9B9B9B;

$brk-small-mobile: 425px;
$brk-mobile: 767px;
$brk-tablet: 1200px;

$img-path: '../img';

$max-width: 960px;

$flexboxgrid-gutter-width: 20px;

$breakpoints:
    sm 50.625em 51.625rem,
    md 62em 61rem,
    lg 75em 71rem;

$desktop-default-side-padding: 150px;
