.press {

    .site-content {
        max-width: $brk-mobile;
        margin-bottom: 60px;
        margin-top: 100px;

        @include brk-tablet {
            margin-top: 120px;
        }

        @include brk-mobile {
            margin-top: 40px;
            padding: 5%;
            margin-bottom: 0;
        }

        div {
            margin-bottom: 60px;
        }

        h1 {
            font-size: 1.4em;
            @include font-playfair-bold;
        }

        h2 {
            @include font-source-sans-semibold;
            font-size: 1.1em;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            color: $medium-grey;
            margin-bottom: 10px;
        }

        .press-partner {
            a {
                text-decoration: none;

                .mail {
                    color: $button-blue;
                    &:hover {
                        text-decoration: underline $button-blue;
                    }
                }

                p {
                    width: auto;
                }
                &:hover {
                    text-decoration: underline $medium-grey;
                }
            }
        }

        .press-logos {
            p {
                margin-bottom: 40px;
            }
        }

        .press-images {

            .image-downloads {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;

                .downloadable {
                    flex-basis: 50%;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    margin: 20px 0;

                    @include brk-mobile {
                        flex-basis: 100%;
                        width: 100%;
                        max-width: 500px;
                    }

                    img {
                        width: 70%;
                        min-width: 280px;
                    }

                    p {
                        margin-top: 20px;
                    }

                    .btn-blue {
                        margin-right: auto;
                    }
                }
            }
        }
    }
}
