.page-generic {
    .site-content {
        padding: 50px 0;

        @include brk-mobile {
            padding: 0 0 30px;
        }

        @include brk-small-mobile {
            h1 {
                font-size: 2.8em;
            }
        }

        h2 {
            font-size: 2em;
            @include font-playfair-bold;
        }
        h3 {
            font-size: 1.6em;
            @include font-source-sans-semibold;

            @include brk-small-mobile {
                font-size: 1.4em;
            }
        }
    }
}
