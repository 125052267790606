
.footer {
    position: relative;
    background: $dark-grey;
    /*width: 100vw;*/
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 35px 14% 40px;
    color: white;

    @include brk-tablet {
        padding: 35px 50px;
    }

    @include font-source-sans;
    &-logo {
        margin: 0 auto;
        img {
            width: 110px;
            margin-left: auto;
        }
    }

    &-info {
        @include font-source-sans;
        font-size: 14px;
        margin: 0 auto;

        .address {
            margin-bottom: 20px;
            line-height: 15px;
        }

        .phone {
            margin-bottom: 15px;
            a {
                text-decoration: none;
                color: $white;
            }
        }

        .links {
            display: flex;
            flex-wrap: wrap;

            .link {
                flex-basis: 20%;
                margin: 5px auto 5px 0;
                a {
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-right: 15px;
                }
            }
            @include brk-mobile {
                width: 90%;
                flex-wrap: wrap;
                margin: 15px 0 5px;

                .link {
                    flex-basis: 50%;
                    margin-bottom: 15px;
                }
            }

            @include brk-small-mobile {
                .link {
                    flex-basis: 30%;
                }
            }
        }
    }

    &-sm {
        display: flex;
        .sm-icon {
            cursor: pointer;
            height: 20px;
            margin-right: 20px;
        }
    }

    &-newsletter {
        @include font-source-sans;
        font-size: 14px;
        margin: 0 auto;

        .btn {
            display: inline-block;
            margin-bottom: 20px;
        }

        #newsletter {
            display: flex;
            flex-direction: column;

            span {
                font-size: 10px;
            }

            &-email {
                @include font-source-sans;
                margin: 20px 0 10px;
                color: $headline-grey;
                background: transparent;
                border: none;
                border-radius: 0;
                font-size: 14px;
                border-bottom: 1px solid $light-grey;
                max-width: 80%;
                @include brk-small-mobile {
                    max-width: 90%;
                }

                &::placeholder {
                    font-style: italic;
                    color: $medium-grey;
                }
                &:active {
                    border: none;
                    border-bottom: 2px solid $button-blue;
                }
            }

            .btn-blue {
                margin-right: auto;
                background: transparent;
            }

        }

        #gdpr {
            display: flex;
            flex-direction: row;
            font-size: 10px;
            margin-bottom: 20px;
            line-height: 15px;

            &-checkbox {
                margin-right: 15px;
            }

            a {
                color: white;
            }
        }
    }

    @media screen and (max-width: 946px) {
        padding: 35px 0;
        height: max-content;
        > * {
            flex-basis: 50%;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        &-logo {
            img {
                margin: 0 auto;
                display: block;
            }
        }

        &-sm {
            a {
                margin: 0 auto;
                display: block;
            }
            padding: 0 15%;
            .sm-icon {
                margin: 0 auto;
            }
        }
    }
    @include brk-small-mobile {
        > * {
            flex-basis: 80%;
        }

        &-logo {
            img {
                margin: 0;
                margin-right: auto;
            }
        }

        &-info {
            font-size: 14px;
        }

        .links {
            width: 100%;

        }

        &-sm {
            a {
                margin:0 auto;
            }
            padding: 0;
            padding-right: 45%;
        }

        &-newsletter {
            font-size: 14px;

            span, #dsgvo, #gdpr {
                font-size: 12px !important;
            }
        }
    }
}

