.page-dsr-ai {
    color: $medium-grey;

    .overlay {
        * {
            @include font-source-sans;
        }
    }

    a {
        text-decoration: none;
    }

    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .title-image {
        .image {
            height: 100%;
            background-position: 50% 20%;
        }
    }

    .content {
        max-width: 620px;
        padding-top: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include custom-max-brk(800px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &.intro {
            max-width: 780px;
        }
    }

    .advantages {
        display: flex;
        column-gap: 30px;
        justify-content: center;
        flex-wrap: wrap;

        .box {
            max-width: 240px;
            padding: 20px;
            background-color: $super-light-grey;
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            .nbr {
                font-size: 70px;
                font-weight: 700;
                line-height: 1;
                color: $light-grey;
                @include font-source-sans-bold;
            }

            .icon {
                height: 46px;
                margin-right: auto;
                margin-bottom: 0;
            }

            .text {
                color: $dark-grey;
                margin: 25px 0;
                @include font-source-sans-semibold;

                strong {
                    color: $sky-blue;
                    font-weight: 700;
                }
            }

            svg {
                margin-top: auto;
            }

            .overlay {
                h3 {
                    font-size: 40px;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 20px;
                }

                h4 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    @include font-playfair-bold
                }

                .sub-title, .rich-text {
                    font-size: 16px;
                    margin-bottom: 40px;
                }

                .image {
                    margin-top: 20px;
                }

                .showcase {
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    font-weight: 600;
                    margin-top: 6px;
                    margin-bottom: 20px;
                    @include font-source-sans-semibold;

                    a {
                        text-decoration: none;
                        color: $medium-grey;
                        display: flex;
                        align-items: center;
                        margin-left: 4px;
                        cursor: pointer;
                    }

                    svg {
                        margin-top: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .showcases {
        position: relative;
        width: 100%;
        margin-bottom: 80px !important;

        h4 {
            position: relative;
            margin-bottom: 20px;
            z-index: 5;
        }

        &-wrapper {
            position: relative;
            display: flex;
            overflow-x: scroll;
            padding-bottom: 15px;
            gap: 15px;
            width: 100%;
            z-index: 3;
        }

        .shadow-w {
            display: none;
            position: absolute;
            height: 300px;
            right:0;
            width: 30px;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .7) 100%);
            z-index: 4;

            @include brk-mobile {
                display: block;
            }
        }

        .showcase {
            min-width: 300px;
            max-width: 300px;
            height: 270px;

            &-image {
                width: 100%;
                height: 170px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-text {
                height: 100px;
                background-color: $super-light-grey;
                padding: 20px 30px;

                p {
                    font-size: 16px;
                }

                a {
                    font-size: 14px;
                }
            }

            &:last-of-type {
                @include brk-mobile {
                    margin-right: 40px;
                }
            }
        }

        &-buttons {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: space-between;
            margin-top: 5px;

            @include brk-mobile {
                display: none;
            }

            img {
                margin-bottom: 0;
                height: 16px;
                cursor: pointer;

                &[data-showcases-l] {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .banner {
        display: flex;
        flex-wrap: wrap;

        .image {
            flex: 1;
            min-width: 270px;
            min-height: 160px;
        }

        .text {
            flex: 3;
            padding: 30px 18px;
            background-color: $box-blue;
            display: flex;
            flex-direction: column;
            min-width: 280px;

            h5 {
                color: $light-blue;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 20px;
                @include font-source-sans-semibold;
            }

            h3 {
                color: $white;
                font-size: 32px;
                @include font-playfair-bold;
            }

            svg {
                align-self: flex-end;
            }
        }

        h3 {
            line-height: 1;
        }

        .sub-title {
            margin-bottom: 40px;
            @include font-source-sans;
        }
    }

    .intro {
        line-height: 26px;
        font-size: 18px;

        .icon-title {
            margin-bottom: 40px;

            h1 {
                line-height: 1;
            }
        }

        .image-text {
            img {
                width: 100%;
            }

            .text {
                strong {
                    @include font-source-sans-semibold;
                }
            }

            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid $light-grey;
        }

        .why, .banner, .advantages {
            margin-bottom: 80px;
        }

        img {
            margin-bottom: 20px;
        }

        h1 {
            font-size: 48px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 20px;
            font-size: 18px;
            @include font-playfair-bold;
        }

        > div {
            margin-bottom: 20px;

            strong {
                font-weight: 700;
            }
        }
    }

    .offers {
        display: grid;
        column-gap: 20px;
        grid-template-columns: 1fr 1fr;
        color: $medium-grey;
        margin-bottom: 30px;
        @include brk-mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .full-w {
            grid-column: span 2;
            margin-bottom: 70px;
            text-align: center;
            @include brk-mobile {
                margin-bottom: 20px;
            }

            h3 {
                margin-bottom: 20px;
                font-size: 18px;
                @include font-playfair-bold;
            }

            div {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .offer {
            width: 300px;

            &:nth-child(2n) {
                margin-top: 40px;
                @include brk-mobile {
                    margin-top: 30px;
                }
            }

            &:nth-child(2n-1) {
                margin-top: -40px;
                @include brk-mobile {
                    margin-top: 30px;
                }
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }

            .image {
                width: 100%;
                height: 300px;
                margin-bottom: 18px;
                padding: 17px;

                div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    span {
                        color: $white;
                        font-size: 12px;
                        font-weight: 400;
                        font-style: normal;
                        margin-right: 4px;
                    }
                }
            }

            .info {
                color: $medium-grey;

                h4 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                .description {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }

    .projects {
        background-color: $super-light-grey;
        padding: 60px 30px;

        h3, .sub-title {
            text-align: center;
            color: $medium-grey;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
        }

        .sub-title {
            margin: 0 auto;
            max-width: 620px;
            font-size: 16px;
            font-weight: 400;
            margin-top: 30px;
            line-height: 24px;
        }

        .project {
            display: flex;
            justify-content: flex-end;
            padding: 42px;
            margin: 0 auto;
            margin-top: 40px;
            max-width: $max-width;


            .intro {
                min-height: 440px;
                width: 300px;
                padding: 30px;
                background-color: $super-light-grey;

                h4, p {
                    font-size: 16px;
                    line-height: 22px;
                    color: $medium-grey;
                    margin-bottom: 30px;
                    font-weight: 400;
                }

                h4 {
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
    }

    .overlay {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($black, 0.7);;
        height: 100%;
        display: none;
        z-index: -10;
        overflow-y: auto;
        overflow-x: hidden;

        &.active {
            display: block;
            z-index: 999999;

            .right-content {
                animation: slideInFromLeft 400ms ease-out;
            }
        }

        .right-content {
            margin-left: auto;
            right: 0;
            top: 0;
            padding: 30px 42px;
            background-color: $white;
            width: 60%;
            overflow-y: scroll;
            position: relative;
            @include brk-tablet {
                width: 70%;
            }
            @include brk-mobile {
                width: 100%;
                padding: 20px 15px;
            }

            .rich-text {
                h4 {
                    font-size: 18px;
                    margin-bottom: 30px;
                    @include font-playfair-bold
                }

                p {
                    line-height: 24px;
                }
            }

            .idea {
                display: flex;
                column-gap: 80px;
                margin-bottom: 40px;
                padding-bottom: 20px;
                border-bottom: 1px $super-light-grey solid;
                @include brk-mobile {
                    flex-wrap: wrap;
                }

                .text {
                    flex: 2;
                    min-width: 260px;
                    margin-bottom: 20px;

                    h4 {
                        font-size: 18px;
                        font-weight: 700;
                        color: $medium-grey;
                        margin-bottom: 20px;
                        @include font-playfair-bold;
                    }

                    div {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $medium-grey;
                    }
                }

                .categories {
                    flex: 1;

                    .agents {
                        display: flex;
                        flex-wrap: wrap;
                        row-gap: 10px;
                        column-gap: 20px;
                        margin-top: 12px;

                        .agent {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            max-width: 80px;
                            margin-bottom: 0;

                            &-image {
                                width: 80px;
                                height: 80px;
                                border-radius: 100%;
                                position: relative;
                                overflow: hidden;
                                margin-bottom: 8px;

                                img {
                                    position: absolute;
                                    transform: translate(-50%, -50%);
                                    left: 50%;
                                    top: 50%;
                                    height: 100%;
                                    border-radius: 100%;
                                }
                            }

                            .title {
                                font-size: 13px;
                                line-height: 15px;
                                letter-spacing: 0px;
                                text-transform: uppercase;
                                text-align: center;
                                margin-bottom: 0;
                                @include font-source-sans-semibold;
                            }
                        }
                    }

                    h5 {
                        font-size: 11px;
                        font-weight: 300;
                        color: $medium-grey;
                        margin-bottom: 5px;
                    }

                    div {
                        margin-bottom: 20px;
                    }

                    .similar-areas {
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: $medium-grey;
                            line-height: 20px;
                            margin-top: 8px;
                        }

                    }
                }
            }

            .headline-images {
                h4 {
                    color: $medium-grey;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                .text {
                    color: $medium-grey;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 20px;
                    line-height: 24px;
                }

                .image {
                    margin-bottom: 20px;
                }

                .slider {
                    margin-top: 30px;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    margin-right: -42px;
                    overflow-x: scroll;
                    display: flex;
                    column-gap: 25px;
                    @include custom-max-brk(700px) {
                        margin-right: -15px;
                    }

                    img {
                        max-height: 500px;
                        @include custom-max-brk(580px) {
                            max-height: 90vw;
                        }
                    }
                }
            }

            header {
                padding-top: 6px;
                padding-right: 18px;
                position: static;
                box-shadow: none !important;
                height: auto;
                margin-bottom: 25px;
                @include brk-tablet {
                    padding: 0px 8px;
                    margin-top: -2px;
                }

                .header-right {
                    margin-left: auto;

                    .item {
                        color: $light-grey;
                        font-size: 16px;

                        &.active, &:hover {
                            color: $medium-grey;
                        }

                        .item-border {
                            border-color: $light-grey;
                        }
                    }

                    .menu-dash {
                        border-color: $light-grey;
                    }
                }
            }

            .contact-us {
                background-color: $super-light-grey;
                padding: 32px 42px;
                margin-top: 30px;

                p {
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    font-size: 16px;
                    color: $light-grey;
                    @include font-source-sans;
                }
            }

            h3 {
                text-align: left;
                font-size: 42px;
                margin-top: 20px;
                margin-bottom: 30px;
                @include font-playfair;
            }

            .img-wrapper {
                .image {
                    height: auto;
                    width: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .close-overlay {
                    position: fixed;
                    right: 5.5%;
                    top: 26px;
                    width: 40px;
                    cursor: pointer;
                }
            }
        }
    }

    .btn, .overlay-btn {
        cursor: pointer;
    }

    .redactor-content {
        width: 100%;
        max-width: 100%;

        strong {
            @include font-source-sans-semibold;
        }

        p {
            font-size: 18px;
        }
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
