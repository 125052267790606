.compass-navigation-wrapper {
    display: none;
    @include brk-mobile {
        display: flex;
    }
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: transparent;

    .logo {
        position: absolute;
        top: 80px;
        left: 50px;
        width: 150px;
    }
}

section.compass-navigation {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    overflow: hidden;

    @include brk-mobile {
        display: block;
    }

    .compass-direction {
        position: absolute;
        z-index: 999;

        .wave {
            position: absolute;
            max-height: 15vw;
        }

        &.dir-top .wave {
            bottom:2px;
            width: 110%;
            transform: scale3d(1, 0, 1) rotate3d(0, 0, 0, 180deg);
            transform-origin: bottom;
            top: unset !important;
        }

        &.dir-bottom .wave {
            top: calc(-15vw + 2px);
            width: 110%;
            transform: scale3d(1, 0, 1) rotate3d(0, 0, 0, 0);
            transform-origin: bottom;
            bottom: unset !important;
        }

        &.dir-left .wave {
            right: calc(-50vh + 2px);
            width: 100vh;
            top: -55vh;
            transform: scale3d(0, 2, 1) rotate3d(0, 0, 0, 90deg);
            transform-origin: bottom center;
            left: unset !important;
        }

        &.dir-right .wave {
            left: calc(-50vh + 2px);
            width: 100vh;
            top: -55vh;
            transform: scale3d(0, 2, 1) rotate3d(0, 0, 0, -90deg);
            transform-origin: bottom center;
            right: unset !important;
        }

        .compass-header {
            position: absolute;
            display: flex;
            height: 100%;
            width: 100%;
            padding: 20px 0;
            line-height: 1.2;
            background-color: #ffffff;

            h2 {
                color: $light-grey;
                @include font-source-sans;
                font-weight: bold;
                font-size: 24px;
                text-align: center;
                width: 100%;
            }
        }

        .navigation-info {
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            color: $light-grey;
            font-weight: bold;
            font-size: 12px;
            width: 100%;

            span {
                margin: 10px auto;
                @include font-source-sans;
            }

            img {
                opacity: 0;
                padding: 5px 0;
                width: 20px;
                margin:0 auto;
                transform: rotate(180deg);
                &.switch {
                    transform: rotate(0);
                }
            }
        }

        &.dir-bottom{
            .compass-header{
                flex-direction: column-reverse;
                .navigation-info {
                    top: 10px;
                    bottom: auto;
                    flex-direction: column-reverse;

                    img {
                        transform: rotate(0deg);

                        &.switch {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &.dir-right {
            .compass-header {
                h2 {
                    margin: auto;
                    padding-top: 10px;
                }
                .navigation-info {
                    transform: rotate(-90deg);
                    justify-content: center;
                    top: 0;
                    bottom: 0;
                    pointer-events: none;
                    flex-direction: column-reverse;
                    left: -10px;

                    img {
                        transform: rotate(0deg);
                        padding: 25px 0;

                        &.switch {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &.dir-left {
            .compass-header {
                h2 {
                    margin: auto;
                    padding-bottom: 10px;
                }
                .navigation-info {
                    transform: rotate(270deg);
                    justify-content: center;
                    top: 0;
                    pointer-events: none;
                    bottom: 0;
                    flex-direction: column;
                    right: -10px;

                    img {
                        transform: rotate(180deg);
                        padding: 25px 0;

                        &.switch {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }

    .compass-nav-helper {
        position: absolute;
        z-index: 10;
        color: $white;
        line-height: 1.3;
        font-size: 14px;

        @include font-source-sans-semibold;
        a {
            color: $white;
            text-decoration: none;

            &:focus {
                outline: 2px dashed $white;
            }
        }

        p {
            @include font-source-sans-semibold;
            @include brk-mobile {
                font-size: 16px;
            }
            @include brk-small-mobile {
                font-size: 14px;
            }
        }

        &-top {
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 50px;
            min-height: 0;
            text-align: center;
            padding-top: 10px;

            img {
                position: absolute;
                top: -10px;
                right: 30%;
                width: 25px;
            }
        }

        &-right {
            right: -10px;
            top: 33%;
            max-width: 240px;
            max-height: 240px;
            height: 100%;

            img {
                position: absolute;
                top: 50px;
                right: 10px;
                width: 25px;
                transform: rotate(90deg);
            }

            p {
                transform: rotate(-90deg);
            }
        }

        &-bottom {
            bottom: 10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            min-height: 0;
            img {
                position: absolute;
                top: -10px;
                left: 23%;
                width: 25px;
                transform: rotate(180deg);
            }
        }

        &-left {
            left: -10px;
            top: 38%;
            max-width: 240px;
            max-height: 240px;
            height: 100%;
            img {
                position: absolute;
                top: -78px;
                left: 10px;
                width: 25px;
                transform: rotate(-90deg);
            }
            p {
                transform: rotate(90deg);
            }
        }
    }

    .dir-left, .dir-right {
        height: 100%;
        width: 120px;
    }

    .dir-top, .dir-bottom {
        height: 120px;
        width: 100%;
    }

    .dir-left {
        left: 0;
        top: 0;
        transform: translate3d(-120px, 0, 0);

        h2 {
            transform: rotate(270deg);
        }
    }

    .dir-right {
        right: 0;
        top: 0;
        transform: translate3d(120px, 0, 0);

        h2 {
            transform: rotate(270deg);
        }
    }

    .dir-top {
        top: 0;
        left: 0;
        transform: translate3d(0, -120px, 0);
    }

    .dir-bottom {
        bottom: 0;
        left: 0;
        transform: translate3d(0, 120px, 0);
    }
}

@keyframes waveHor {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 400vw 0;
    }
}

@keyframes waveVert {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 100vh;
    }
}



