body.home {
    footer {
        display: none;
    }

    #fullpage {
        .fp-watermark {
            display: none;
        }
    }

    #news .fp-overflow {
        height: 100vh;
        overflow-y: scroll;
    }

    #home-button {
        display: none !important;
    }

    .page-switch {
        display: none !important;
    }

    .slide-content {
        position: relative;
        display: flex;
        width: 100vw;
        height: 100vh;
        transition: all .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        overflow: hidden;
        .slide-background {
            .slide-img, .slide-video {
                position: absolute;
                left: 50%;
                top: 50%;
                object-fit: cover;
                transform: translate3d(-50%, -50%, 0);
                min-width: 100%;
                min-height: 100%;
                height: 100%;
                width: auto;
                //height: auto;
                z-index: -1;

                @include brk-mobile {
                    height: 100vh;
                    top:unset;
                    left: 60%;
                    transform: translate3d(-40%, 0, 0);
                }
            }
        }

        .arrow {
            position: absolute;
            right: 63%;
            margin-top: 100px;
            width: 60px;
            opacity: 0;
            transition: all 1s ease;
            transition-delay: 0s;
            transform: translate3d(100%, 0, 0);

            &.active {
                opacity: 1;
                transition-delay: .5s;
                transform: translate3d(0, 0, 0);
            }

            @include brk-tablet {
                right: 73%;
            }
        }

        .title {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 45vh;
            padding-left: 10%;
            color: white;
            width: 80%;

            &.active {
                h1, h2 {
                    transform: translateY(-200px) !important;
                    opacity: 0 !important;
                }
                p {
                    transform: translateY(100%) !important;
                    opacity: 0 !important;
                }

                .button {
                    opacity: 0 !important;
                    .button-border {
                        transform: translateX(-100%) !important;
                    }
                }
            }

            h1, h2 {
                transform: translateY(-200px);
                opacity: 0;
                transition: all .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                color: $white;
            }

            p {
                margin: 20px 0 100px;
                @include font-source-sans;
                font-size: 1.4em;
                letter-spacing: 1px;
                transform: translateY(100%);
                opacity: 0;
                transition: all .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            }

            .button {
                cursor: pointer;
                color: white;
                text-decoration: none;
                @include font-source-sans-bold;
                font-size: 1.4em;
                letter-spacing: 3px;
                padding-bottom: 10px;
                width: max-content;
                opacity: 0;
                transition: all .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                overflow: hidden;
                .button-border {
                    margin-top: 5px;
                    width: auto;
                    transform: translateX(-100%);
                    border-bottom: 3px solid white;
                    transition: all 700ms ease;
                }
            }
        }
        @include brk-mobile {
            .title {
                width: 80%;
                padding-top: 50vh;
                padding-left: 0;
                margin: 0 auto;
                h1, h2 {
                    font-size: 2.5em;
                }
                p {
                    font-size: 16px;
                    margin: 10px 0 50px;
                }
                .button {
                    font-size: 16px;
                }
            }
        }
        @include brk-small-mobile {
            .title {
                p {
                    margin: 10px 0 40px;
                }
            }
        }
    }

    .section.active .slide-content {
        transition-delay: 1s;
        background: rgba(0, 0, 0, 0.3);
        .title {
            p {
                transition-delay: .6s;
                transform: translateY(0);
                opacity: 1;
            }
            h1, h2 {
                transition-delay: .4s;
                transform: translateY(0);
                opacity: 1;
            }
            .button {
                transition-delay: .8s;
                opacity: 1;
                .button-border {
                    transition-delay: 1s;
                    transform: translateX(0);
                }
            }
        }

    }

    .dots {
        position: absolute;
        color: white;
        display: flex;
        flex-direction: column;
        right: 84px;
        top: 50%;
        z-index: 555;
        .dot {
            display: flex;
            flex-direction: column;
            transition: all 500ms ease;
            width: 25px;
            padding: 15px 0;
            margin-left: auto;
            &-border {
                pointer-events: none;
                border-bottom: 2px solid white;
            }
            &.active, &:hover {
                width: 35px;
            }
        }
        @include brk-mobile {
            margin: 0 auto;
            flex-direction: row;
            top: 90vh;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 200px;
            .dot {
                width: 25px;
                padding: 0 25px;
                height: auto;
                margin: 0 auto;
                &-border {
                    height: 25px;
                    transition: all 500ms ease;
                    border-right: 2px solid white;
                    border-bottom: none;
                }
                &.active, &:hover {
                    width: 0;
                    .dot-border {
                        height: 35px;
                    }
                }
            }
        }
        @include brk-mobile {
            display: none;
        }
    }
}
