.project-banner {
    position: relative;
    height: 230px;
    width: 100%;
    overflow: hidden;
    z-index: 5;

    @include brk-mobile {
        height: auto;
    }

    &-image {
        filter: brightness(.7);
        width: 110%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        min-height: 230px;
        z-index: -1;
        @include brk-mobile {
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            max-height: 450px;
        }
    }


    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 55;
        padding: 40px;
        color: $white;
        width: 100%;
        height: 100%;

        h2 {
            font-size: 2.4em !important;
            color: $white !important;
        }

        p {
            font-size: 18px !important;
        }

        .buttons {
            position: absolute;
            bottom: 20px;
            margin-top: 10px;
            right: 40px;
            display: flex;
            flex-direction: column;
            width: 100%;

            .btn-box-arrow {
                margin: 5px 0;
                margin-left: auto;
                min-width: 190px;

                @include brk-mobile {
                    margin-left: 0;
                    margin-right: auto;
                }
            }

            @include brk-mobile {
                position: relative;
                bottom:0;
                margin: 40px auto 0;
                right: 0;
            }
        }
    }
}
