.service-details {
    position: relative;
    overflow: hidden;
    margin: 105px auto;
    width: 90%;

    &-image {
        width: 100%;
        margin-bottom: 30px;
    }

    h2 {
        margin-bottom: 40px;
        font-size: 42px;
        line-height: 55px;
    }

    &-body {
        display: flex;
        justify-content: space-between;

        @include brk-mobile {
            flex-direction: column-reverse;
        }
    }

    &-content {
        width: 100% !important;
        max-width: 50% !important;
        margin: 0 auto 0 0 !important;

        @include brk-mobile {
            max-width: unset !important;
        }

        &.redactor-content {
            h3 {
                margin: 0 0 20px;
            }
        }
    }

    &-info {
        width: 100%;
        max-width: 250px;

        h4 {
            @include font-source-sans;
            font-size: 14px;
            margin-bottom: 5px;
            color: $light-grey;
        }

        p {
            color: $medium-grey;
            font-size: 18px;
            margin-bottom: 30px;
        }

        .btn {
            display: block;
            text-align: center;
            margin-bottom: 30px;
            margin-right: 50%;
        }
    }
}
