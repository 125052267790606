body, html {
    height: 100%;
}

html body {
    display: block;
}

body.no-scroll {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

.site-wrapper {
    width:100%;
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 100%;
    overflow: hidden;
    position: relative;

    .site-content {
        .tab-nav {
            margin: auto auto 30px;
            max-width: 960px;
            width: 90%;
            @include font-source-sans;
            color: $light-grey;
            display: flex;
            grid-gap: 15px;

            @include brk-mobile {
                position: absolute;
                top: 32px;
                left: 5%;
            }

            span {
                @include font-source-sans-semibold;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 16px;
                cursor: pointer;

                &:hover, &.active {
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 4px;
                }
            }
        }

        .tab-content {
            display: none;

            &.active  {
                display: block;
            }
        }
    }
}

$directions: top, left, right, bottom;
$props: margin, padding;

@for $i from 1 through 10 {
    @each $direction in $directions {
        @each $prop in $props {
            .#{$prop}-#{$direction}-#{$i * 10} {
                #{$prop}-#{$direction}: $i * 10px;
            }
        }
    }
}

@for $i from 1 through 10 {
    @each $direction in $directions {
        @each $prop in $props {
            @include brk-mobile {
                .mobile-#{$prop}-#{$direction}-#{$i * 10} {
                    #{$prop}-#{$direction}: $i * 10px;
                }
            }
        }
    }
}

.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.align-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.align-right-text {
    text-align: right;
}

.hidden{
    display: none !important;
}

.hidden-mobile {
    @include brk-mobile {
        display: none !important;
    }
}

.hidden-desktop {
    display: none !important;
    @include brk-mobile {
        display: block !important;
    }
}

.btn-blue {
    @include font-source-sans;
    color: $button-blue;
    font-size: 16px;
    text-decoration: none;
    width: auto;
    cursor: pointer;
    border: none;
    border-radius: 0;

    &:hover {
        transition: all .5s ease;
        text-decoration: $button-blue underline;
    }

    &.border {
        @include font-source-sans-semibold;
        text-decoration: none;
        letter-spacing: 2px;
        padding: 0 0 3px;
        border-bottom: 2px solid $button-blue;
        transition: all .1s ease-in;
        line-height: 28px;
        text-transform: uppercase;
    }

    &:active, &:focus {
        outline: none;
    }
}

.btn-box-arrow {
    color: $medium-grey;
    @include font-source-sans-semibold;
    font-size: 12px;
    letter-spacing: 1px;
    background: $white url("/assets/img/elements/icons/pfeil@2x.png") no-repeat;
    border: 1px solid transparent;
    padding: 9px 80px 10px 10px;
    background-position: 90%;
    background-size: 20%;
    text-decoration: none;
    &:hover {
        transition: all .5s ease;
        background: transparent url("/assets/img/elements/icons/pfeil_white@2x.png") no-repeat;
        background-position: 90%;
        background-size: 20%;
        color: $white;
        border: 1px solid $white;
    }

    &.inverted {
        background: transparent url("/assets/img/elements/icons/pfeil_white@2x.png") no-repeat;
        background-position: 90%;
        background-size: 20%;
        color: $white;
        border: 1px solid $white;
        text-decoration: none;
        &:hover {
            color: $medium-grey;
            background: $white url("/assets/img/elements/icons/pfeil@2x.png") no-repeat;
            background-position: 90%;
            background-size: 20%;
            border: 1px solid transparent;
        }
    }
}

.btn-box {
    color: $medium-grey;
    @include font-source-sans-semibold;
    font-size: 16px;
    letter-spacing: 1px;
    border: 1px solid transparent;
    padding: 7px 10px 8px;
    &:hover {
        transition: all .5s ease;
        color: $white;
        border: 1px solid $white;
    }

    &.grey {
        padding: 7px 15px 8px;
        @include brk-mobile {
            margin: 20px 50% 0 0;
        }
        font-size: 12px;
        text-decoration: none;
        color: $medium-grey;
        border: 1px solid $medium-grey;
        &:hover {
            color: $white;
            background: $medium-grey;
            border: 1px solid $medium-grey;
        }
    }
}

.btn-box.inverted {
    color: $white;
    @include font-source-sans-semibold;
    font-size: 16px;
    letter-spacing: 1px;
    border: 1px solid white !important;
    padding: 7px 20px 8px;
    &:hover {
        transition: all .5s ease;
        color: $medium-grey;
        background: $white;
        border: 1px solid transparent;
    }
}

.cc-message {
    @include font-source-sans;
    font-size: 16px;
    color: $white;
    margin-right: 20px;
}

.cc-banner {
    padding: 25px;
    display: flex;
    bottom: 0;
}


.move-in-view {
    opacity: 0 !important;
    transform: translateY(50px) scale(1);
    transform-origin: 50% 0;

    @include brk-mobile {
        transform: translateY(50px) scale(1.1);
    }
    &.inview {
        transition: all 1s ease;
        opacity: 1 !important;
        transform: translateY(0) scale(1);
    }
}


h1 {
    @include font-playfair;
    font-size: 3em;
    color: $white;
}

h2, h3, h4 {
    color: $medium-grey;
}

h2 {
    @include font-playfair;
    font-size: 2.8em;
    color: $medium-grey;
}

h3 {
    @include font-playfair-bold;
    font-size: 1.4em;
    letter-spacing: 0.4px;
}

h4 {
    @include font-playfair;
    font-size: 1.4em;
}

p {
    @include font-source-sans;
}

.background {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    //filter: brightness(.7);
    background: rgba(0,0,0,.3);

    img {
        position: absolute;
        left: 0;
        min-height: 100vh;
        height: 100vh;
        z-index: -1;
    }

    @include brk-mobile{
        height: 20vh;
        max-height: 390px;
        img {
            min-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.cookie-consent-banner {
    @include font-source-sans;
    margin: 20px auto;
    max-width: 90%;
    text-align: center;
    border: 1px solid $super-light-grey;
    display: flex;
    flex-direction: column;
    padding: 50px;

    @include brk-small-mobile {
        padding: 40px 20px;
        max-width: none;
    }

    h4 {
        margin-bottom: 40px !important;
        line-height: normal;
    }

    &-body {
        padding: 0 25px;
        font-size: 16px !important;
        line-height: 1.4em !important;

        @include brk-small-mobile {
            padding: 0;
        }

        a {
            color: $medium-grey !important;
            text-decoration: underline $medium-grey !important;
        }
    }
}

