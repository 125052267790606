@mixin clear {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin brk-small-mobile {
    @media (max-width: $brk-small-mobile) {
        @content;
    }
}

@mixin brk-mobile {
    @media (max-width: $brk-mobile) {
        @content;
    }
}

@mixin brk-tablet {
    @media (max-width: $brk-tablet) {
        @content;
    }
}

@mixin brk-max-width {
    @media (max-width: $max-width) {
        @content;
    }
}

@mixin custom-max-brk($max-width) {
    @media (max-width: $max-width) {
        @content;
    }
}

@mixin font-source-sans {
    font-family: SourceSansPro-Regular, sans-serif;
}

@mixin font-source-sans-semibold {
    font-family: SourceSansPro-SemiBold, sans-serif;
}

@mixin font-source-sans-bold {
    font-family: SourceSansPro-Bold, sans-serif;
}

@mixin font-playfair {
    font-family: PlayfairDisplay-Regular, serif;
}

@mixin font-playfair-bold {
    font-family: PlayfairDisplay-Bold, serif;
}

@mixin preload-images ($element, $images) {

    $urls: "";
    @each $image in $images {
        $urls: $urls + "url(#{$image}) ";
    }

    #{$element}:after {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        visibility: hidden;
        content: unquote($urls);
    }

}
