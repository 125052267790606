body.contact {
    footer {
        display: none;
    }
}

.hbspt-form {
    margin-top: 40px;
    @include font-source-sans;
    color: $medium-grey;
    div.hs_firstname input,
    div.hs_company input,
    div.hs_email input,
    div.hs-dateinput input,
    div.hs_mobilephone input {
        display: inline-block;
        width: 100%;
        height: 40px;
        padding: 9px 10px;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
        color: #33475b;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #cad5e2;
        margin-top: 18px;
        margin-bottom: 3px;
        outline: none;
        background: transparent;
        &::placeholder {
            color: #33475b;
            opacity: 0.5;
        }
    }

    div.hs_message{
        height: 120px;
        margin-bottom: 18px;
        .input, textarea{
            height: 100%;
            width: 100%;
        }
        textarea{
            padding: 10px;
        }
    }

    @include brk-tablet {
        margin-top: 10px;
    }

    div.hs_preferred_contact_method{
        margin-bottom: 18px;
        li {
            margin-top: 6px;
        }
    }

    div.hs_mobilephone input {
        margin-bottom: 18px;
    }

    div.hs-dateinput input {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }

    ul > li > label.hs-error-msg {
        float: none;
        width: auto;
        padding: 0;
        line-height: 18px;
        white-space: normal;
        font-weight: normal;
        color: #f2545b;
        font-size: 14px;
    }

    div.hs-submit input {
        font-family: SourceSansPro-SemiBold,sans-serif;
        text-decoration: none;
        letter-spacing: 2px;
        padding: 0 0 3px;
        border: 0;
        background: transparent;
        border-bottom: 2px solid #0076af;
        -webkit-transition: all .1s ease-in;
        -o-transition: all .1s ease-in;
        -moz-transition: all .1s ease-in;
        transition: all .1s ease-in;
        line-height: 28px;
        color: #0076af;
        font-size: 16px;
        width: auto;
        cursor: pointer;
        margin-top: 40px;
        margin-bottom: 100px;
        text-transform: uppercase;
    }
}

.header-box {
    position: fixed;
    top:0;
    right:0;
    width: 60%;
    height: 100px;
    background: white;
    z-index: 555555;

    @include brk-tablet {
        width: 70%;
        height: 80px;
    }

    @include brk-mobile {
        display: none;
    }
}

div.hs_submit.hs-submit > div.actions > input {
    font-family: SourceSansPro-SemiBold,sans-serif;
    text-decoration: none;
    letter-spacing: 2px;
    padding: 0 0 3px;
    border-bottom: 2px solid #0076af;
    -webkit-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -moz-transition: all .1s ease-in;
    transition: all .1s ease-in;
    line-height: 28px;
}

.contact-wrapper {
    background: $white;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 60%;
    transition: transform 500ms ease;
    transform: translateX(100%);
    overflow-x: hidden;

    @include brk-mobile {
        position: static;
        width: 100%;
    }

    &.active {
        transform: translateX(0);
    }

    .close-btn {
        position: absolute;
        top:0;
        right:0;
        margin: 100px;
        width: 25px;
        transition: opacity 700ms ease;
        opacity: 1;
        cursor: pointer;
        z-index: 555555;

        @include brk-mobile {
            position: relative;
            margin:0 0 20px;
        }
    }

    .contact-start {
        position:fixed;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        opacity: 0;
        padding: 140px 100px;
        transition: opacity 500ms ease;
        //overflow: hidden;
        z-index: 555;

        &.active {
            position:absolute;
            opacity: 1;
        }

        @include brk-mobile {
            position: relative !important;
            padding: 100px 10% 40px;
            height: auto;
            width: 100%;
        }

        .links {
            position: relative;
            display: flex;
            width: 100%;
            margin-top: auto;
            margin-bottom: 20px;
            flex-wrap: wrap;
            min-height: 80px;
            max-width: 300px;

            .link {
                flex-basis: 50%;
                margin: 10px 0;
                a {
                    flex-basis: auto;
                    text-transform: uppercase;
                    font-size: 14px;
                    width: auto;
                    margin-right: auto;
                }
            }

            span {
                @include font-playfair;
                color: $medium-grey;
                font-size: 14px;
                letter-spacing: 1px;
                margin-top: 40px;
            }

            @include brk-mobile {
                position: relative;
                left:0;
                bottom:0;
                margin-top: 60px;
                max-width: 100%;

                .link {
                    flex-basis: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .contact-form, .newsletter, .jobs, .alert {
        position: fixed;
        left: 0;
        right:0;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 200px 100px;
        transition: opacity 500ms ease;
        display: none;
        opacity: 0;
        background-color: $white;
        //overflow-y: scroll;

        z-index: 55;
        &.active {
            display: flex;
            position: absolute;
            opacity: 1;
            animation: slideIn .7s ease;
            z-index: 5555555;
        }

        h4 {
            @include font-source-sans-semibold;
            margin: 25px 0;
            font-weight: 700;
            font-size: 14px;
        }

        @include brk-mobile {
            top:0;
            margin-bottom: 0;
            padding: 40px 10% 0;
        }

        @include brk-small-mobile {
            min-height: 90%;
            padding: 60px 5%;
        }
    }


    .alert {
        display: none;
        &.active {
            display: flex;
        }

        padding: 0;
        @include brk-mobile {
            top:0;
            //margin-bottom: 0;
            padding:0;
        }

        @include brk-small-mobile {
            margin: 0;
            min-height: 0;
            padding: 0;

            .close-btn {
                top: 40px;
            }
        }

        .alert-content {
            margin: 50px 0;
            padding: 100px;
            h4 {
                @include font-source-sans;
                font-size: 18px;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                color: $medium-grey;
            }
            @include brk-mobile {
                top:0;
                //margin-bottom: 0;
                padding: 40px 10% 0;
            }

            @include brk-small-mobile {
                margin: 100px 0 0;
                min-height: 90%;
                padding: 20px 5%;
            }
        }
        .project-banner {
            position: relative;
            bottom: 0;
            left: 0;
            margin-top: 100px;
            transition: opacity 1000ms ease;
            opacity: 0;
            z-index: 55555555;

            @include brk-small-mobile {
                margin-top: 60px;
            }
        }
    }


    .contact-form {

        &-info {
            position: relative;
            color: $medium-grey;

            &-bubble {
                position: absolute;
                right: 0;
                top: -50px;
                width: 105px;
                height: 105px;
                border-radius: 50%;
                background-color: $box-blue;
                color: $white;
                @include font-source-sans-semibold;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: normal;
                text-align: center;
                user-select: none;
                pointer-events: none;
                transform: rotate(7.5deg);

                @include brk-mobile {
                    right: 0;
                    top: -20px;
                    width: 80px;
                    height: 80px;
                    font-size: 10px;
                    line-height: 11px;
                }
            }

            .logo-headline{
                display: flex;
                column-gap: 15px;
                align-items: center;
                margin-bottom: 40px;

                h2{
                    margin-bottom: 0;
                }

               svg{
                margin-top: 5px;
               }
            }

            .paragraph{
                margin-bottom: 12px;
            }

            h3, h2 {
                margin-bottom: 40px;
                @include font-playfair-bold;
            }

            h2 {
                max-width: 90%;
                @include brk-mobile {
                    font-size: 2.4em;
                }
            }

            p {
                line-height: 20px;
                @include font-source-sans;
            }

            ul {
                @include font-source-sans;
                margin: 20px 0;

                li {
                    list-style: none;
                    margin: 5px 0;
                }
                li::before {
                    content: '';
                    display: inline-block;
                    height: 10px;
                    width: 25px;
                    background-image: url('/assets/img/elements/icons/pfeil.png');
                    background-repeat: no-repeat;
                    background-size:100%;
                    background-position-x: -9px;
                }

            }

        }

        .hubspot-form {
            margin-top: 60px;
        }

        #contact-form {
            margin-top: 60px;
            display: flex;
            flex-direction: column;

            p {
                @include font-source-sans-bold;
                font-size: 16px;
                margin-bottom: 20px;
                color: $medium-grey;
            }

            input[type=text], input[type=email], textarea, input[type=date] {
                @include font-source-sans;
                margin: 15px 0;
                color: $medium-grey;
                background: none;
                font-size: 16px;
                padding-bottom: 10px;
                border: none;
                border-bottom: 1px solid $light-grey;
                border-radius: 0;
                width: 100%;
                max-width: 80%;
                &::placeholder {
                    font-style: italic;
                    color: $light-grey;
                }

                &:focus {
                    outline: none;
                    border-width: 2px;
                    margin-bottom: 14px;
                }

                @include brk-tablet {
                    max-width: none;
                }

                @include brk-mobile {
                    max-width: 90%;
                }
            }

            textarea {
                resize: none;
                min-height: 80px;
            }

            .btn-blue {
                background: none;
                border-radius: 0;
                margin-top: 40px;
                margin-right: auto;
            }

            #country {
                opacity: 0;
                height: 0;
                padding: 0;
                margin: 0;
                border: 0;
                &:focus {
                    border:0;
                    outline:0;
                }
            }
        }
    }

    .newsletter {
        #newsletter {
            @include font-source-sans;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            label {
                font-size: 14px;
                letter-spacing: .5px;
                color: $medium-grey;
                margin-bottom: 40px;
            }

            h4 {
                @include font-source-sans;
                font-size: 18px;
                margin-bottom: 20px;
            }

            &-email {
                @include font-source-sans;
                margin: 10px 0;
                color: $medium-grey;
                background: transparent;
                border: none;
                padding-bottom: 10px;
                border-radius: 0;
                border-bottom: 1px solid $light-grey;
                max-width: 80%;
                @include brk-small-mobile {
                    max-width: 90%;
                }

                &::placeholder {
                    font-size: 16px;
                    font-style: italic;
                    color: $light-grey;
                }

                &:focus {
                    outline: none;
                    border-width: 2px;
                }
            }
            .btn-blue {
                background: none;
                margin-right: auto;
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }
        }
        #gdpr {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            margin-bottom: 40px;
            line-height: 15px;
            color: $light-grey;
            @include font-source-sans;

            &-checkbox {
                background-color: transparent;
                margin-right: 15px;

                label::before {
                    content: "";
                    border-radius: 0;
                    border: 1px solid $light-grey;
                }
            }

            a {
                color: $light-grey;
            }
        }
        a {
            margin-right: auto;
        }
    }

    .contact-content {
        @include font-source-sans;
        letter-spacing: 0.4px;
        display: flex;
        margin-bottom: 60px;

        @include brk-mobile {
            display: block;
        }

        .social-media {
            margin-bottom: 40px;
            display: flex;
            &-icon {
                img {
                    cursor: pointer;
                    height: 20px;
                    margin-right: 20px;
                }
            }
        }

        .address {
            color: $medium-grey;
            ul {
                li {
                    margin-bottom: 5px;
                }
            }
            .phone {
                a {
                    text-decoration: none;
                    color: $medium-grey;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                margin-top: 40px;
            }
        }

        .subpages {
            margin-top: 80px;
            margin-bottom: 20px;
            ul {
                li {
                    margin: 30px 0;

                    a {
                        text-transform: uppercase;
                    }
                }
            }

            @include brk-mobile {
                margin-top: 40px;
            }
        }
    }

    .contact-email {
        padding-top: 60px;
        padding-left: 60px;

        @include brk-mobile {
            padding: 0;
            margin-top: 40px;
        }

        ul {
            li {
                margin-bottom: 20px;
            }
        }
    }

    @include brk-tablet {
        width: 70%;
    }

    @include brk-mobile{
        width: 100%;
        height: calc(100% - 100px);
        /*margin-top: 20px;*/
        overflow: hidden;
        margin-bottom: 0;
        left:0;
        transition: none;

        .contact-start {
            flex-direction: column-reverse;
            width: 100%;
            .contact-email {
                width: 100%;
                margin-bottom: 20px;
                padding-top:0;
            }

            .contact-content {
                width: 100%;
            }
        }
    }

    @include brk-small-mobile {
        padding-bottom: 40px;
        .contact-start{
            .contact-content {
                .links {
                    margin-top: 20px;
                    .link {
                        flex-basis: 50%;
                    }
                }
            }
        }
    }
}



@keyframes slideIn {
    from {
        left: 100%;
    }
    to {
        left:0;
    }

}
