header {
    position: fixed;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    z-index: 9999;
    right: 0;
    left: 0;
    top: 0;

    @include brk-mobile {
        height: 0;
    }

    #home-button {
        position: fixed;
        right: 20px;
        top: 20px;
        width: 60px;
        transition: all 400ms ease;
        display: none;
        z-index: 9999;
        @include brk-mobile {
            display: block;
        }

        @include brk-small-mobile {
            right: 10px;
            top: 10px;
        }
    }

    .header {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        margin: 0 auto;
        padding: 30px 60px;
        @include brk-tablet {
            padding: 25px 50px 20px;
        }

        @include brk-mobile {
            padding: 100px 50px;
        }

        @include brk-mobile {
            display: none;
        }

        &-left {
            &-logo {
                img {
                    width: 170px;
                    height: auto;
                    transition: all .4s ease;
                    @include brk-mobile {
                        width: 110px;
                    }
                    @include brk-tablet {
                        width: 150px;
                    }
                    &:hover {
                        transform: scale(0.95);
                    }
                }
                .logo-grey {
                    display: none;
                }
                .logo-white {
                    display: block;
                }
            }

        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include font-source-sans;
            width: 490px;

            @include brk-mobile {
                display: none !important;
            }

            &.active {
                border-color: $light-grey;
                .item {
                    color: $light-grey;
                    .item-border {
                        border-color: $light-grey;
                    }
                    &:hover, &.active {
                        color: $medium-grey;
                        .item-border {
                            transform: translateX(0);
                            border-color: $medium-grey;
                        }
                    }
                }
                .menu-dash {
                    border-color: $light-grey;
                }
            }

            .item {
                font-size: 1em;
                margin: 0 auto;
                color: $white;
                text-decoration: none;
                transition: all 300ms ease;
                overflow: hidden;

                @include brk-tablet {
                    font-size: 1em;
                }

                .item-border {
                    margin: 0 auto;
                    margin-top: 5px;
                    width: 80%;
                    transform: translateX(-115%);
                    border-bottom: 1px solid white;
                    transition: all 450ms ease;
                }

                &:hover, &.active {
                    .item-border {
                        transform: translateX(0);
                    }
                }
            }

            .language {
                cursor: pointer;
                margin: 0 0 0 auto;
                opacity: .3;
                .item-text {
                    text-transform: uppercase;
                }
                .item-border {
                    opacity: 0;
                }

                &.active, &:hover {
                    color: $white;
                    opacity: 1;
                    .item-border {
                        opacity: 0;
                        transform: translateX(-115%);
                    }
                }
            }

            .menu-dash {
                border-right: 1px solid white;
                height: 25px;
            }
        }

        &.active, &.dark {
            background: $white !important;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            transition: all 500ms ease;
            .logo-grey {
                display: block;
            }
            .logo-white {
                display: none;
            }
            .item {
                color: $light-grey;
                &:hover, &.active {
                    color: $medium-grey;
                    .item-border {
                        transform: translateX(0);
                        border-color: $medium-grey;
                    }
                }
            }

            .language {
                color: $light-grey;
                &.active, &:hover {
                    opacity: 1;
                    .item-border {
                        opacity: 0;
                        transform: translateX(-115%);
                    }
                }
            }

            .menu-dash, .item-border {
                border-color: $light-grey;
            }

        }
    }
}

#mobile-language-switcher {
    position: absolute;
    display: none;
    right: 0;
    top: 0;
    width: 90px;
    padding: 10px 15px;
    justify-content: flex-end;
    z-index: 999999;
    @include font-source-sans;

    @include brk-mobile {
        display: flex !important;
    }

    .item {
        font-size: 1.2em;
        color: $white;
        text-decoration: none;
        transition: all 300ms ease;
        overflow: hidden;
        cursor: pointer;
        margin-left: auto;
        opacity: .3;

        @include brk-small-mobile {
            font-size: 1em !important;
        }

        .item-text {
            text-transform: uppercase;
        }

        .item-border {
            opacity: 0;
        }

        &:hover, &.active {
            color: $white;
            opacity: 1;
            .item-border {
                opacity: 0;
                transform: translateX(-115%);
            }
        }
    }
}
