section.page-projects {
    .site-content {
        margin-top: 0;

        .projects {
            margin: 0 auto;
            padding: 50px 0;

            &-header {
                h1 {
                    @include font-playfair-bold;
                    font-size: 1.4em;
                    letter-spacing: 0.4px;
                }

                h1, p {
                    max-width: 35%;
                    margin: 25px auto;
                    width: 90%;

                    @include brk-mobile {
                        max-width: none;
                    }
                }
            }

            &-category {
                position: relative;
                margin: 90px 0 80px auto;
                display: flex;
                width: 90%;

                @include brk-tablet {
                    width: 95%;
                }

                @include brk-mobile {
                    width: 100%;
                    flex-direction: column;
                }

                &-description {
                    position: relative;
                    width: 20%;
                    text-align: left;
                    height: 50%;
                    margin: auto;
                    transform-origin: 0 50%;

                    &.move-in-view {
                        transform: translate3d(100%, 0, 0) scale3d(1.5, 1.5, 1.5) !important;
                        opacity: 0 !important;
                        &.inview {
                            opacity: 1 !important;
                            transform: translate3d(0, 0, 0) scale3d(1, 1, 1) !important;
                        }
                    }

                    &-container {
                        position: relative;
                        margin: auto;
                    }

                    h3 {
                        margin: 0 0 20px 0 !important;
                    }

                    p {
                        max-width: 80%;
                    }

                    @include brk-max-width {
                        width: 25%;
                    }

                    @include brk-mobile {
                        width: 90%;
                        height: 100%;
                        margin: 0 auto 40px;
                    }
                }

                &-entries {
                    position: relative;
                    width: 80%;
                    display: flex;
                    overflow-x: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    transition-delay: .1s;
                    transform-origin: 0 50%;

                    &.move-in-view {
                        transform: translate3d(100%, 0, 0) scale(1.5, 1.5) !important;
                        opacity: 0 !important;
                        &.inview {
                            opacity: 1 !important;
                            transform: translate3d(0, 0, 0) scale(1, 1) !important;
                        }
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }


                    @include brk-max-width {
                        width: 75%;
                    }

                    @include brk-mobile {
                        transition-delay: unset;
                        width: 100%;
                    }

                    .project {
                        position: relative;
                        height: 300px;
                        width: 250px;
                        min-width: 250px;
                        margin: 0;
                        overflow: hidden;
                        background-color: $light-grey;

                        &.move-in-view {
                            transform: translate3d(100%, 0, 0) !important;
                            &.inview {
                                transform: translate3d(0, 0, 0) !important;
                            }
                        }

                        @media screen and (max-width: 500px) {
                            max-width: 50%;
                            .project-client {
                                font-size: 14px;
                            }
                        }

                        .link {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            left: 0;
                            top: 0;
                            z-index: 5555;
                            .overlay {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 15%);
                                z-index: 555;
                            }
                        }

                        .showcase {
                            position: absolute;
                            top: 15px;
                            width: 50%;
                            left: 10px;
                            opacity: 1;
                            display: flex;
                            transition: all .5s ease;
                            z-index: 55555;
                            span {
                                @include font-source-sans;
                                font-size: 13px;
                                color: $white;
                            }
                            img {
                                margin-left: 5px;
                                height: 15px;
                            }
                        }

                        .project-background {
                            width: 100%;
                            height: 100%;
                            z-index: 55;

                            .overlay {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,.7) 100%);
                                z-index: 555;
                            }
                            .project-image {
                                position: absolute;
                                height: 105%;
                                left: 50%;
                                transform: translate3d(-50%, 0, 0);
                                /*transition: clip-path .5s linear;*/
                                /*clip-path: polygon(100% 100%, 0 100%, 0 100%, 0 100%, 0 0, 100% 0);*/
                                z-index: 55;
                            }
                        }


                        .project-client {
                            position: absolute;
                            bottom: 15px;
                            text-align: right;
                            width: 90%;
                            right: 10px;
                            color: $white;
                            font-size: 18px;
                            letter-spacing: 0.5px;
                            word-break: break-word;

                            @include font-source-sans-semibold;
                            z-index: 5555;
                        }

                        &-info {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            padding: 40px 20px;
                            text-align: left;
                            z-index: 5;

                            .project-category {
                                @include font-source-sans;
                                margin: 10px 0;
                                width: 100%;
                                font-size: 12px;
                                color: $white;
                            }

                            .project-type {
                                @include font-source-sans;
                                width: 100%;
                                font-size: 18px;
                                color: $white;
                                line-height: normal;
                            }
                        }
                        &:hover {
                            .link {
                                .overlay {
                                    opacity: 0;
                                    transition: opacity 1s ease;
                                }
                            }

                            .showcase {
                                transform: translate3d(100%, 0, 0);

                                @include brk-small-mobile {
                                    transform: translate3d(60%, 0, 0);
                                }
                            }
                            .project-image, .overlay {
                                animation: clippath .5s linear;
                                clip-path: polygon(0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
                            }

                            @include brk-mobile {
                                .project-image, .overlay {
                                    clip-path: none;
                                    opacity:0;
                                }
                            }
                        }

                        @include brk-max-width {
                            flex-basis: 50%;
                        }

                        @include brk-tablet {
                            flex-basis: 100%;
                        }
                    }
                }
            }

            &-grid {
                margin: 80px auto;
                display: flex;
                flex-wrap: wrap;
                width: $max-width;
                max-width: $max-width;
                justify-content: center;

                .project {
                    flex-basis: 25%;
                    position: relative;
                    height: 300px;
                    max-width: 250px;
                    overflow: hidden;
                    background-color: $light-grey;

                    @media screen and (max-width: 500px) {
                        max-width: 50%;
                        .project-client {
                            font-size: 14px;
                        }
                    }

                    .link {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top: 0;
                        z-index: 5555;
                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0, rgba(0,0,0,0) 15%);
                            z-index: 555;
                        }
                    }

                    .showcase {
                        position: absolute;
                        top: 15px;
                        width: 50%;
                        left: 10px;
                        opacity: 1;
                        display: flex;
                        transition: all .5s ease;
                        z-index: 55555;
                        span {
                            @include font-source-sans;
                            font-size: 13px;
                            color: $white;
                        }
                        img {
                            margin-left: 5px;
                            height: 15px;
                        }
                    }

                    .project-background {
                        width: 100%;
                        height: 100%;
                        z-index: 55;

                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,.7) 100%);
                            z-index: 555;
                        }
                        .project-image {
                            position: absolute;
                            height: 105%;
                            left: 50%;
                            transform: translate3d(-50%, 0, 0);
                            /*transition: clip-path .5s linear;*/
                            /*clip-path: polygon(100% 100%, 0 100%, 0 100%, 0 100%, 0 0, 100% 0);*/
                            z-index: 55;
                        }
                    }


                    .project-client {
                        position: absolute;
                        bottom: 15px;
                        text-align: right;
                        width: 90%;
                        right: 10px;
                        color: $white;
                        font-size: 18px;
                        letter-spacing: 0.5px;
                        word-break: break-word;

                        @include font-source-sans-semibold;
                        z-index: 5555;
                    }

                    &-info {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        padding: 40px 20px;
                        text-align: left;
                        z-index: 5;

                        .project-category {
                            @include font-source-sans;
                            margin: 10px 0;
                            width: 100%;
                            font-size: 12px;
                            color: $white;
                        }

                        .project-type {
                            @include font-source-sans;
                            width: 100%;
                            font-size: 18px;
                            color: $white;
                            line-height: normal;
                        }
                    }
                    &:hover {
                        .link {
                            .overlay {
                                opacity: 0;
                                transition: opacity 1s ease;
                            }
                        }

                        .showcase {
                            transform: translate3d(100%, 0, 0);

                            @include brk-small-mobile {
                                transform: translate3d(60%, 0, 0);
                            }
                        }
                        .project-image, .overlay {
                            animation: clippath .5s linear;
                            clip-path: polygon(0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
                        }

                        @include brk-mobile {
                            .project-image, .overlay {
                                clip-path: none;
                                opacity:0;
                            }
                        }
                    }

                    @include brk-max-width {
                        flex-basis: 50%;
                    }

                    @include brk-tablet {
                        flex-basis: 100%;
                    }
                }

                @include brk-max-width {
                    width: 100%;
                }
                @include brk-small-mobile {
                    width: 100%;

                }
            }
        }
    }
}

body.projects-entry {
    footer {
        display: none;
    }
}

section.page-projects-entry {

    .background {
        left:0;
        transition: left 1s 0s ease;
        &.active {
            transition: left 1s 0.4s ease;
            left: -25%;
            filter: brightness(.7);
        }
    }

    .arrow {
        position: absolute;
        right: 63%;
        top: 20px;
        width: 60px;
        opacity: 0;
        transition-delay: 0s;
        transform: translate3d(100%, 0, 0);

        &.active {
            opacity: 1;
            transition: opacity 1s ease;
            transition-delay: .5s;
            transform: translate3d(0, 0, 0);
            z-index: 1000000000;
        }
        @include brk-tablet {
            right: 5.5%;
            width: 40px;
            top: 18px;
        }
    }

    .project-details {
        top: 0;
    }
}

.project-details {
    background: $white;
    position: absolute;
    height: 100vh;
    right: -100%;
    width: 60%;
    transition: all 1000ms ease;
    overflow-y: scroll;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;

    @include brk-tablet {
        width: 70%;
    }
    @include brk-mobile {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    &.active {
        right: 0;
        .scroll-content {
            opacity: 1;
        }
    }

    &-header {
        .header-image {
            position: relative;
            overflow: hidden;
            display: block;
            margin: 0 auto;
            margin-bottom: 30px;
            width: $max-width;
            max-width: 90%;
            @include brk-mobile {
                max-width: 100%;
            }
        }
    }

    .site-content {
        .contact-us{
            margin: 0 auto;
            max-width: 960px;
            width: 90%;
            background-color: $super-light-grey;
            margin-top: 30px;
            padding: 32px 42px;
        }
    }

    .details-header {
        position: sticky;
        top: 0;
        background: $white;
        width: 100%;
        height: 100px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        z-index: 999999;
        @include brk-mobile {
            display: none;
        }
    }



    &::-webkit-scrollbar {
        width: 1px;
    }
    .title-detail, .description-detail {
        color: $medium-grey;
        max-width: $max-width;
        width: 90%;
        margin: 10px auto;
    }

    h1, h2 {
        @include font-playfair;
        color: $medium-grey;
        margin-bottom: 10px;
    }
}

@include brk-mobile{
    width: 100%;
    .details-header {
        display: none;
    }
}



@keyframes clippath {
    0% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 0, 100% 0);
    }
    50%{
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 0 100%, 0 0, 0 0);
    }
    100%{
        clip-path: polygon(0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
    }
}
