body.dsr-ads {

    header {
        position: absolute !important;

        .header  {

            .header-right {
                display: none;
            }
        }
    }
}

.page-dsr-ads {

    .page-title {
        position: absolute;
        bottom: 0;
        width: 95%;
        max-width: 1440px;
        left: 0;
        right: 0;
        margin: auto;
        padding-bottom: 70px;

        @include brk-max-width {
            padding-bottom: 20px;
            width: 70%;
            left: 5%;
            margin: 0;
        }

        h1 {
            font-size: 3em;
            margin-bottom: 20px;
            color: $white;
            @include font-playfair;
            @include brk-max-width {
                font-size: 2.4em;
                margin-bottom: 15px;
            }
        }

        p {
            font-size: 1.8em;
            color: $white;
            @include font-source-sans;
            @include brk-max-width {
                font-size: 1.3em;
            }
        }
    }

    .site-content {
        position: relative;
        margin: 0;
        padding: 40px 0;

        .tab-navigation {
            position: absolute;
            top:0;
            left:0;
            right:0;
            width: 100%;
            max-width: 1440px;
            margin: auto;
            display: flex;
            align-items: flex-start;
            overflow-x: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            z-index: 99;

            &.float {
                position: fixed;
                background: white;
                box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
                @include brk-mobile {
                    padding-right: 100px
                }

                .tab-navigation-item {
                    padding: 20px 30px !important;
                }
            }

            @include brk-mobile {
                box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
            }

            a {
                text-decoration: none !important;
            }

            &-item {
                font-size: 18px;
                padding: 40px 30px;
                text-align: center;
                color: $light-grey;
                text-decoration: none !important;
                text-transform: uppercase;
                @include font-source-sans;

                @include brk-mobile {
                    padding: 30px;
                }


                &:hover {
                    color: $medium-grey;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .dsr-ad-list {
            position: relative;
            max-width: 1440px;
            margin: 0 auto;
            width: 90%;
            z-index: 1;

            @include brk-mobile {
                padding-top: 40px;
            }

            &-category {
                padding-top: 60px;

                &-description {
                    position: relative;
                    width: 100%;
                    max-width: 710px;

                    h2 {
                        color: $medium-grey;
                        font-size: 32px;
                    }

                    p {
                        color: $light-grey;
                        font-size: 18px;
                    }
                }
            }

            &-entry {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                padding: 60px 0;


                .dsr-ad-video {
                    position: relative;
                    display: flex;
                    padding: 20px;

                    @include brk-max-width {
                        width: 100%;
                    }

                    @include brk-small-mobile {
                        padding: 0;
                    }

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        height: 650px;
                    }

                    &-inner {
                        position: relative;
                        width: 297px;
                        height: 627px;
                        border-radius: 35px;
                        margin: auto;
                        overflow: hidden;

                        video {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 100%;
                            z-index: -1;
                        }
                    }
                }


                .dsr-ad-description {
                    position: relative;
                    padding: 80px 0 0 80px;
                    color: $medium-grey;
                    min-width: 375px;

                    @include brk-max-width {
                        width: 100%;
                        padding: 80px 0 0;
                    }

                    @include brk-mobile {
                        min-width: unset;
                    }

                    .dsr-ad-cat {
                        @include font-source-sans-bold;
                        font-size: 18px;
                        margin-bottom: 20px;
                        text-transform: uppercase;
                        color: $light-grey;
                    }
                    .dsr-ad-client {
                        font-size: 32px;
                        margin-bottom: 60px;

                        @include brk-mobile {
                            margin-bottom: 30px;
                        }
                    }

                    .dsr-ad-code {
                        @include font-source-sans;
                        font-size: 18px;
                        margin-bottom: 40px;
                        color: $light-grey;


                        a {
                            color: $button-blue;
                            text-decoration: underline $button-blue;
                        }
                    }


                    .dsr-ad-qr {
                        width: 200px;
                    }
                }

            }
        }
    }
}
