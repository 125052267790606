.page-solutions {
    @include brk-mobile {
        .title-image {
            display: none;
        }
    }

    .site-content {
        .solutions-title {
            width: 90%;
            max-width: $max-width;
            margin: 0 auto 80px;

            @include brk-mobile {
                margin: 0 auto 40px;
            }

            h1 {
                @include font-playfair;
                font-size: 2.8em;
            }

            p {
                font-size: 18px;
            }
        }

        .solutions-wrapper {
            text-align: center;
            background: $super-light-grey;

            &.white {
                background: $white !important;
            }

            .btn-blue {
                display: inline-block;

                &.mobile {
                    display: none;
                    @include brk-mobile {
                        display: inline-block;
                    }
                }
            }

            .solutions {
                position: relative;
                padding: 30px 0;
                max-width: 960px;
                width: 95%;
                margin: 0 auto;

                &-nav {
                    display: flex;
                    justify-content: space-between;
                    overflow-x: scroll;
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                    transform: scale(1.5, 1.5) translate(50%, 0);

                    &.move-in-view {
                        &.inview {
                            transform: scale(1, 1) translate(0, 0);
                        }
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    @include brk-mobile {
                        grid-gap: 15px;
                        padding-bottom: 15px;
                    }

                    &-item {
                        position: relative;

                        &-inner {
                            position: relative;
                            cursor: pointer;
                            background-color: $white;
                            min-width: 165px;
                            height: 185px;
                            transition: background-color .5s ease;

                            h3 {
                                position: absolute;
                                bottom: 20px;
                                left:0;
                                right: 0;
                                margin: auto;
                                font-size: 22px;
                                @include font-playfair;
                                color: $light-grey;
                                text-align: center;
                            }
                        }

                        &:hover, &.active {

                            .solutions-nav-item-inner {
                                background-color: $box-blue;

                                h3 {
                                    color: $white;
                                }
                            }


                            img {
                                opacity: 0;
                            }

                            .icon-light {
                                opacity: 1;
                            }
                        }

                        .icon-light {
                            transition: opacity .5s ease;
                            opacity: 0;
                        }

                        img {
                            position: absolute;
                            top: 40px;
                            left:0;
                            right:0;
                            max-width: 50%;
                            max-height: 50%;
                            transition: opacity .5s ease;
                            opacity: .6;
                            margin: auto;
                            width: auto;
                        }
                    }
                }

                &-container {
                    margin: 25px 0;
                    padding: 20px;
                    background-color: $white;

                    @include brk-mobile {
                        margin: 0;
                    }
                }

                &-inner {
                    text-align: left;
                    color: $medium-grey;

                    .redactor-content {
                        width: 100% !important;
                    }

                    h3 {
                        margin: 0 0 30px 0;
                    }

                    .accordion {
                        &-tile {
                            border-bottom: 1px solid $light-grey;
                        }

                        &-title {
                            p {
                                @include font-source-sans-semibold;
                                color: $dark-grey;
                            }
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            padding: 15px 0;

                            &.active {
                                .accordion-button {
                                    .horizontal {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }

                        &-button {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .line {
                                transform-origin: 50% 50%;
                                position: absolute;
                                width: 100%;
                                height: 2px;
                                background-color: black;

                                &.horizontal {
                                    transition: transform .3s ease;
                                    transform: rotate(90deg);
                                }
                            }
                        }

                        &-content {
                            padding: 10px 0 15px;
                            background-color: white;
                            overflow: hidden;
                            color: $dark-grey;
                        }
                    }
                }
            }

            .ad-favourites {
                position: relative;
                background-color: $white;
                padding-bottom: 30px;
                z-index: 1;

                .showcase-banner {
                    position: relative;
                    padding: 15px;
                    width: 90%;
                    background-color: $box-blue;
                    margin: 40px auto;
                    text-align: left;
                    text-decoration: none;

                    .app-text {
                        @include font-source-sans-semibold;
                        color: $light-blue;
                        margin-bottom: 10px;
                        font-size: 10px;
                    }

                    .claim {
                        max-width: 260px;
                        @include font-playfair;
                        color: $white;
                        font-size: 18px;
                    }

                    img {
                        position: absolute;
                        bottom: 15px;
                        right: 15px;
                        width: 38px;
                    }
                }

                &-entry {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 70px 0;
                    background-color: $white;

                    &.bg-light {
                        background-color: $super-light-grey;
                    }

                    &.bg-light .dsr-ad-description {
                        background-color: $white;

                        @include brk-mobile {
                            background-color: transparent;
                        }
                    }

                    @include brk-mobile {
                        padding: 45px 0;
                    }

                    &-inner {
                        position: relative;
                        display: flex;
                        margin: auto;
                        max-width: 800px;
                        width: 90%;

                        @include brk-mobile {
                            flex-direction: column;
                        }
                    }

                    .dsr-ad-video {
                        position: absolute;
                        left:0;
                        top: 50%;
                        transform: translateY(-50%);
                        height: auto;
                        width: auto;
                        display: flex;
                        margin-left: 70px;
                        z-index: 3;

                        @include brk-mobile {
                            position: relative;
                            top:0;
                            transform: none;
                            width: 100%;
                            max-width: 250px;
                            margin: 0 auto;
                            padding: 0;
                        }

                        img {
                            position: relative;
                            height: 500px;
                            pointer-events: none;
                            z-index: 2;

                            @include brk-mobile {
                                width: 100%;
                                height: auto;
                            }
                        }

                        &-inner {
                            position: absolute;
                            border-radius: 35px;
                            margin: auto;
                            overflow: hidden;
                            top:0;
                            left:0;
                            right:0;
                            bottom:0;
                            height: calc(100% - 16px);
                            width: calc(100% - 16px);
                            z-index: 1;

                            iframe, video {
                                width: 100%;
                                height: 100%;
                                position: relative;
                                background-color: $white;
                            }
                        }
                    }

                    .dsr-ad-title {
                        text-align: left;
                        font-size: 36px;
                        margin: 0 0 30px 0;
                        text-transform: uppercase;
                        @include font-playfair;
                        color: $medium-grey;
                    }

                    .dsr-ad-description {
                        position: relative;
                        color: $medium-grey;
                        width: 100%;
                        height: 400px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        text-align: left;
                        padding: 30px 30px 30px 50%;
                        margin: 50px 0;
                        background-color: $super-light-grey;

                        @include brk-mobile {
                            min-width: unset;
                            height: auto;
                            background-color: transparent;
                            padding: 0;
                            margin: 30px 0 0;
                        }

                        .dsr-ad-descr {
                            @include font-source-sans;
                            font-size: 12px;
                            margin-bottom: auto;

                            .claim {
                                margin-top: 10px;
                                font-size: 12px !important;
                                @include font-source-sans-semibold;
                            }

                            @include brk-mobile {
                                font-size: 16px;
                                margin-bottom: 30px;

                                .claim {
                                    font-size: 16px;
                                }
                            }
                        }

                        .dsr-ad-qr {
                            margin-top: auto;
                            width: 78px;
                            height: 78px;

                            img {
                                width: 100%;
                            }
                        }

                        .dsr-ad-contact {
                            margin-top: 10px;

                            p {
                                color: $light-grey;
                                font-size: 12px;
                            }

                            a {
                                margin-top: 5px;
                                font-size: 14px;
                                line-height: 16px;
                            }
                        }
                    }

                }
            }
        }
    }
}
