.inquiries {
    display: flex;
    background: $super-light-grey;
    overflow: hidden;

    @include brk-max-width {
        flex-direction: column;
    }

    .contact-us {
        flex-basis: 45%;
        padding: 30px 5%;
        margin: 0 auto;
        p {
            color: $light-grey;
            margin: 10px auto;
        }

        @include brk-max-width {
            flex-basis: 100%;
            padding: 20px 30px;
            margin:0;
        }
    }

    .contact-us-links {
        flex-basis: 55%;
        display: flex;
        padding: 10px 0;
        justify-content: center;

        @include brk-max-width {
            flex-basis: 100%;
        }

        a {
            text-decoration: none;
            color: $medium-grey;
            @include font-source-sans;

            &:hover {
                text-decoration: underline $light-grey;
            }
        }

        &-standard {
            width: 60%;
            border-right: 1px solid $headline-grey;
            border-left: 1px solid $headline-grey;
            padding: 25px 5%;
            display: flex;
            flex-wrap: wrap;

            a {
                margin: 5px 5%;
            }
            @include brk-mobile {
                padding: 5px 5%;
            }

            @include brk-max-width {
                border-left: 0;
                width: 50%;
            }
        }

        &-sm {
            position: relative;
            width: 40%;
            padding: 25px 5%;

            .sm-links {
                margin: 10px 5%;
                a {
                    margin-right: 15px;
                }
            }

            img {
                width: 30px;
                height: 30px;
                margin-right: auto;
            }
            h5 {
                @include font-source-sans;
                color: $light-grey;
                margin: 5px 5%;
            }

            a {
                margin: 5px auto;
            }
            @include brk-mobile {
                padding: 5px 5%;
            }

            @include brk-max-width {
                width: 50%;
            }
        }

        @include brk-small-mobile {
            max-width: 90%;
            display: block;
            &-standard {
                display: flex;
                position: relative;
                width: 90%;
                border: none;
                margin: auto;
                border-bottom: 1px solid $super-light-grey;
                padding: 0;
            }
            &-sm {
                display: block;
                position: relative;
                width: 90%;
                margin: 25px auto;
                padding: 0;
                img {
                    margin: 0 auto;
                }
            }
        }
    }
}
