.fade-in {
    animation: fade 1s forwards;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes clippath {
    0% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 0, 100% 0);
    }
    50%{
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 0 100%, 0 0, 0 0);
    }
    100%{
        clip-path: polygon(0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%);
    }
}
