.title-image {
    position: relative;
    height: 350px;
    overflow: hidden;
    img {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate3d(-50%, 0, 0);
        min-height: 350px;
        min-width: 100%;
        z-index: -1;

        @include brk-mobile {
            height: 100%;
            min-height: 0;
        }
    }
    @include brk-mobile {
        height: 150px;
        img {
            width: $brk-mobile;
        }
    }
}

.site-content {
    margin: 40px auto 0;
    overflow: hidden;
    min-height: calc(80vh - 200px);

    @include brk-mobile {
        width: 100%;
        margin-top: 50px;
    }

    h1, h2, h3, p {
        color: $medium-grey;
        line-height: normal;
        word-break: break-word;
    }
    h1, h2 {
        margin: 25px 0;
    }
    h3 {
        margin: 20px 0;
        line-height: 1.2em;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    strong {
        @include font-source-sans-semibold;
    }
    em {
        font-style: italic;
    }

    li {
        em {
            font-style: normal;
        }
    }

    .ai-overlay {
        font-size: 18px;
    }

    .showcase {
        display: flex;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        margin-top: 6px;
        margin-bottom: 20px;
        @include font-source-sans-semibold;

        a {
          text-decoration: none;
          color: $medium-grey;
          display: flex;
          align-items: center;
          margin-left: 4px;
          cursor: pointer;
        }
        svg {
          margin-top: 0;
          margin-left: 10px;
        }
    }

    .intro {
        width: 90%;
        margin: 0 auto;
        display: flex;
        max-width: $max-width;

        .intro-text {
            flex-basis: 75%;
            max-width: 500px;
            margin-right: 10%;
            .headlines {
                .heading {
                    margin-bottom: 60px;

                    h3, h1 {
                        @include font-playfair-bold;
                        font-size: 1.4em;
                    }

                    p {
                        margin: 15px 0;
                    }
                }

                strong {
                    @include font-source-sans-semibold;
                }
            }
        }

        .info-box {
            flex-basis: 25%;
            padding-top: 20px;
            //margin: 0 auto;
            .info {
                margin-bottom: 40px;
                h4 {
                    color: $light-grey;
                    text-transform: capitalize;
                    @include font-source-sans;
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        @include brk-mobile {
            width: 90%;
            flex-direction: column-reverse;
            margin-bottom: 30px;

            .intro-text {
                margin-right: 0;
            }

            .info-box {
                display: flex;
                width: 100%;
                padding-top: 0;
                flex-wrap: wrap;
                max-width: 500px;
                margin-right: auto;
                margin-bottom: 20px;

                .info {
                    margin: 10px 15px 0 0;
                }
            }
        }

        @include brk-small-mobile {
            .info-box {
                .info {
                    margin: 10px 15px 0 0;
                }
            }
        }
    }

    .video-container, figure .video-wrapper {
        position: relative;
        overflow: hidden;
        margin-bottom: 40px;
        display: flex;

        iframe, video {
            display: block;
            width: 100% !important;
            height: auto !important;
            aspect-ratio: 16/9;
        }
    }

    .big-picture-left, .big-picture-right, .big-picture-top {
        position: relative;
        width: 100%;
        margin: 40px 0;
        //min-height: 400px;
        .big-picture {
            position: relative;
            width: 90%;
            overflow: hidden;
            z-index: 5;
            img {
                position: relative;
                width: 100%;
            }
        }

        .big-picture-description {
            position: relative;
            width: 270px;
            margin: -5% 5% 0 auto;
            padding-bottom: 20px;
            min-width: 30%;
            z-index: 55;

            h4 {
                @include font-source-sans-semibold;
                margin-bottom: 30px;
            }
        }

        @include brk-tablet {
            flex-direction: column;
            .big-picture {
                width: 100%;
                height: auto;
                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }

            .big-picture-description {
                width: 90%;
                margin: 20px auto;
                max-width: none;
            }
        }

        @include brk-mobile {
            .big-picture-description {
                width: 90%;
                padding: 30px 0;
                margin:0 auto;
            }
        }
    }

    .big-picture-left {
        .big-picture {
            img {
                left: 0;
            }
        }
    }

    .big-picture-right {
        .big-picture {
            img {
                right:0;
            }
        }
    }

    .big-picture-top {
        width: $max-width;
        max-width: 90%;
        display: block;
        margin: 40px auto !important;

        .big-picture {
            width: 100%;
            img {
                margin:0 auto;
            }
        }

        .big-picture-description {
            padding: 60px 0 40px;
            margin: 0 auto;
            width: 100%;
            p {
                margin: 10px 0;
            }
        }

        @include brk-mobile {
            max-width: 100%;

            .big-picture-description {
                width: 90%;
            }
        }
    }


    .picture-slider {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        .picture-slide {
            background-color: $super-light-grey;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            opacity: 0;
            transition: opacity .5s ease;
            flex-direction: row;
            width: 100%;
            height: 100%;
            z-index: 555;

            .picture-container {
                width: 60%;
                .slide-picture {
                    display: block;
                    left:0;
                    width: 100%;
                }
            }

            .slide-box {
                background-color: $super-light-grey;
                width: 40%;
                padding: 10px 20px;
                display: flex;
                flex-direction: column;

                .slide-count {
                    display: flex;
                    p {
                        @include font-playfair;
                        font-size: 18px;
                        letter-spacing: 1px;
                    }
                    #total-slides {
                        color: $light-grey;
                        padding-top: 10px;
                    }
                    height: 10%;
                }
                .slide-box-description {
                    position: absolute;
                    @include font-source-sans;
                    padding: 50px 20px;
                    left:0;
                    right:0;
                    margin: auto 30px auto auto;
                    width: 30%;

                    h5 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 12px !important;
                    }
                    @include brk-tablet {
                        padding: 30px 10%;

                        margin: 0;
                        width: 100%;

                        h5 {
                            font-size: 18px;
                        }
                        p {
                            font-size: 14px !important;
                        }
                    }
                    @include brk-small-mobile {
                        padding: 50px 20px;
                    }
                }
            }
            &.active {
                position: relative;
                opacity: 1;
            }
            @include brk-tablet {
                background-color: white;
                display: inline-grid;
                .picture-container, .slide-box {
                    width: 100% !important;
                }
                .slide-box {
                    position: relative;
                    height: 180px;
                }

                .slide-button {
                    bottom: 32%;
                }
            }

            @include brk-mobile {
                .slide-box {
                    height: 200px;
                }
            }
            @include brk-small-mobile {
                .slide-box {
                    height: 250px;
                }
            }
        }
        .slide-button {
            position: absolute;
            bottom: 20px;
            right: 25px;
            cursor: pointer;
            z-index: 55555;
            img {
                width: 50px;
                z-index: 55555;
            }
        }
    }

    .idea{
        display: flex;
        column-gap: 80px;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px $super-light-grey solid;
        @include brk-mobile{
          flex-wrap: wrap;
        }

        .text{
          flex: 2;
          min-width: 260px;
          margin-bottom: 20px;

          h4{
            font-size: 18px;
            font-weight: 700;
            color: $medium-grey;
            margin-bottom: 20px;
            @include font-playfair-bold;
          }

          div{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $medium-grey;
          }
        }

        .categories{
          flex: 1;
          
          .agents{
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 20px;
            margin-top: 12px;

            .agent{
              display: flex;
              flex-direction: column;
              align-items: center;
              max-width: 80px;
              margin-bottom: 0;
  
              &-image{
                width: 80px;
                height:80px;
                border-radius: 100%;
                position: relative;
                overflow: hidden;
                margin-bottom: 8px;
                img{
                  position: absolute;
                  transform: translate(-50%,-50%);
                  left: 50%;
                  top: 50%;
                  height:100%;
                  border-radius: 100%;
                }
              }

              .title{
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0px;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 0;
                @include font-source-sans-semibold;
              }
            }
          } 

          h5{
            font-size: 11px;
            font-weight: 300;
            color: $medium-grey;
            margin-bottom: 5px;
          }

          div{
            margin-bottom: 20px;
          }

          .similar-areas{
            p{
              font-size: 14px;
              font-weight: 400;
              color: $medium-grey;
              line-height: 20px;
              margin-top: 8px;
            }
            
          }
        }
    }

    .headline-images{
        .img-wrapper {
            margin-bottom: 20px;
        }
    
        .slider {
            margin-top: 30px;
            margin-bottom: 20px;
            padding-bottom: 10px;
            margin-right: -42px;
            overflow-x: scroll;
            display: flex;
            column-gap: 25px;
            @include custom-max-brk(700px){
              margin-right: -15px;
            }
  
            img{
              max-height: 500px;
              @include custom-max-brk(580px){
                max-height: 90vw;
              }
            }
        }
    }  
}

