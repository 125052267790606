.redactor-content {
    width: 90%;
    max-width: $max-width;
    margin: 0 auto;
    @include font-source-sans;

    h1, h2, h3 {
        margin: 20px 0;
        line-height: normal;
    }

    h4, h5, h6 {
        margin: 10px 0;
        line-height: normal;
    }

    h5, h6 {
        @include font-source-sans-semibold;
        font-size: 16px;
    }

    h5 {
        color: $medium-grey;
    }

    h6 {
        color: $light-grey;
    }

    p {
        margin: 15px 0;
        font-size: 16px;
        line-height: 24px;
    }

    blockquote {
        color: $light-grey;
        letter-spacing: 0.8px;
        @include font-source-sans;
        text-align: center;
        margin: 10px 0;
    }

    pre {
        box-sizing: border-box;
        width: 100%;
        margin: 20px 0;
        @include font-source-sans;
        font-size: 14px;
        line-height: 20px;
        background: $medium-grey;
        border: 1px solid $medium-grey;
        padding: 10px;
        color: $white;
    }

    ul, ol {
        @include font-source-sans;
        color: $medium-grey;
        margin: 20px 0;
        margin-left: 50px;
        li {
            line-height: normal;
            margin: 10px 0;
        }
    }

    a {
        color: $medium-grey;
        &:hover {
            color: $dark-grey;
        }
    }

    p {
        em {
            font-style: italic;
        }
    }

    ul{
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    img {
        width: 100%;
    }

    figure{
        margin: 20px auto;

        figcaption{
            color: lighten($medium-grey, 10);
            font-size: 14px;
            margin-left: 12px;
            margin-top: 1px;
            font-style: italic;
        }
    }

    table {
        border: 1px solid $black;
        width: 100%;
        text-align: left;
    }
    table td, table th {
        border: 1px solid $light-grey;
        padding: 5px;
        @include font-source-sans;
        color: $medium-grey;
    }
    table th {
        text-align: center;
    }

    table thead {
    }

    table thead th {
        font-weight: bold;
    }

    table tfoot {
        font-weight: bold;
    }

}
